import "../../styles/scss/loading-spinner.scss";

const Loading = function() {
    return (
        <div className="loading-background">
            <div className="loading-spinner"></div>
        </div>
    )
}

export default Loading;