import { ReactComponent as LeagueLabLogo } from "../../svgs/vendors/LeagueLab.svg";
import Button from "../commmon/Button";

import "../../styles/scss/leagueapps.scss";

type LeagueLabButtonProps = Readonly<{
    onClick?: () => void
}>

const LeagueLabButton = (props: LeagueLabButtonProps) => {

    return (
        <div className="integration leagueapps">
            <Button className="logo" type="button" onClick={props.onClick}>
                <LeagueLabLogo className="leagueapps"/>
            </Button>
        </div>
    )
}

export default LeagueLabButton;
