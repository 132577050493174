import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Card from "../commmon/Card";
import PasswordResetForm from "./forms/PasswordResetForm";

const PasswordResetForce = () => {
    const { user } = useAuth();
    const nav = useNavigate();

    function onFormSuccess() {
        nav("/logout", { replace: true });
    }

    return (
        <div id="admin-profile">
            <section>
                <Card className="display-only">
                    <Card.Header>
                        <h3 className="center">Hello, {user?.firstName}. You need to reset your password.</h3>
                    </Card.Header>
                    <Card.Content>
                        <PasswordResetForm
                            onFormSuccess={onFormSuccess}
                            isForcedReset={true}
                        />
                    </Card.Content>
                </Card>
            </section>
        </div>
    )
}

export default PasswordResetForce;