import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Card from "../commmon/Card";

const OrgSelection = () => {
    const { user } = useAuth();
    const nav = useNavigate();

    function onOrgSelected(id: number) {
        nav(`/org/${id}`);
    }

    return (
        <section>
            <h2>Select an Organization</h2>
            {
                user?.organizations.map((org) => {
                    return (
                        <Card key={org.id} className="full" onClick={() => onOrgSelected(org.organizationId)}>
                            <Card.Header>
                                <h3>{ org.organization.name }</h3>
                            </Card.Header>
                            <Card.Footer>
                                {org.organization.location}
                            </Card.Footer>
                        </Card>
                    )
                })
            }
        </section>
    )
}

export default OrgSelection;
