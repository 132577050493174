import { useEffect, useState } from "react";
import { useOrgData } from "../../context/OrgContext";
import Question from "./Question";
import Card from "../commmon/Card";
import SectionForm from "./forms/SectionForm";

import { ReactComponent as EditIcon } from "../../svgs/pencil.svg";

type SectionProps = Readonly<{
    section: AwardSection
    isDoneEditing?: () => void
}>

const Section = (props: SectionProps) => {
    const { reloadSeasonData } = useOrgData();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [newQuestion, setNewQuestion] = useState<boolean>(false);

    useEffect(() => {
        if (!props.section?.id) {
            setIsEditing(true);
        }
    }, [props.section.id]);

    function onEdit() {
        setIsEditing(true);
    }

    function onCancelEdit() {
        setIsEditing(false);
        if (props.isDoneEditing) {
            props.isDoneEditing();
        }
    }

    function onFormSuccess() {
        setIsEditing(false);
        if (props.isDoneEditing) {
            props.isDoneEditing();
        }

        reloadSeasonData();
    }

    return (
        <section>
            {
                isEditing ? (
                    <SectionForm 
                        section={props.section} 
                        onFormCancel={onCancelEdit}
                        onFormSuccess={onFormSuccess}
                    />
                ) : (
                    <>
                        <h3 className="section-title">
                            {props.section.title}
                            <div className="icon" onClick={onEdit}><EditIcon /></div>
                        </h3>
                        {
                            props.section.questions?.map(x => (
                                <Question
                                    key={x.id}
                                    section={props.section}
                                    question={x}
                                    onQuestionCancel={() => setNewQuestion(false)}
                                />
                            ))
                        } 
                        {
                            newQuestion ? (
                                <Question 
                                    section={props.section}
                                    question={{
                                        allowSelfTeamVote: true,
                                        allowSelfVote: true
                                    } as AwardQuestion}
                                    onQuestionCancel={() => setNewQuestion(false)}
                                />
                            ) : null
                        }
                        <div className="new-question">
                            <Card onClick={() => setNewQuestion(true)}>
                                <Card.Content>
                                    <div>Add Question</div>
                                </Card.Content>
                            </Card>
                        </div>
                    </>
                )
            }
        </section>
    )
}

export default Section;
