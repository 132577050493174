import { FormEvent } from "react";
import { useAuth } from "../../../context/AuthContext";
import Form, { IFormCallback } from "../../commmon/Form";
import FormRow from "../../commmon/FormRow";
import InputField from "../../fields/InputField";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";
import { useAlert } from "../../../context/AlertContext";
import { apiCall } from "../../../functions";

type PasswordResetFormProps = Readonly<{
    isForcedReset?: boolean
}>

const PasswordResetForm = (props: PasswordResetFormProps & IFormCallback) => {
    const { user } = useAuth();
    const { setAlertMessage } = useAlert();

    async function onSubmit(e: FormEvent<HTMLFormElement>, formData: GenericObject): Promise<boolean> {
        let hasError = false;
        const bodyData: GenericObject = {
            newPassword: formData["new-password"]
        };

        if (!props.isForcedReset) {
            if (!formData["current-password"]) {
                setAlertMessage("You must provide your current password.", AlertTypeEnum.ERROR);
                hasError = true;
            }
            else if (formData["current-password"] === formData["new-password"]) {
                setAlertMessage("Your new and old password can not be the same.", AlertTypeEnum.WARNING);
                hasError = true;
            }
            else {
                bodyData["currentPassword"] = formData["current-password"]
            }
        }

        if (!formData["new-password"]) {
            setAlertMessage("You must provide a password to change to.", AlertTypeEnum.ERROR);
            hasError = true;
        }
        else if (formData["new-password"] !== formData["confirm-password"]) {
            setAlertMessage("Your new passwords do not match!", AlertTypeEnum.WARNING);
            hasError = true;
        }

        if (!hasError) {
            try {
                const result = await apiCall<User>(`/user/update`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(bodyData)
                });

                setAlertMessage("Your password was updated successfully.", AlertTypeEnum.SUCCESS);
                props.onFormSuccess();
            }
            catch (err) {
                console.error(err);
                setAlertMessage((err as Error).message, AlertTypeEnum.ERROR);
                if (props.onFormError) {
                    props.onFormError();
                }
                return false;
            }
            finally {
                return true;
            }
        }
        else {
            return true;
        }
    }

    return (
        <Form 
            onSubmitClicked={onSubmit}
            onCancelClicked={props.onFormCancel}
            hideCancel
        >
            <h3></h3>
            <InputField 
                hidden={true} 
                name="email" 
                type="email" 
                autoComplete="email"
                defaultValue={user?.email}
                disabled={true}
            />
            {
                !props.isForcedReset ? (
                    <FormRow className="full">
                        <InputField 
                            type="password" 
                            name="current-password" 
                            placeholder="Current Password" 
                            autoComplete="current-password"
                        />
                    </FormRow>
                ) : null
            }
            
            <FormRow className="full">
                <InputField 
                    type="password" 
                    name="new-password" 
                    placeholder="New Password" 
                    autoComplete="new-password"
                />
            </FormRow>
            <FormRow className="full">
                <InputField 
                    type="password" 
                    name="confirm-password" 
                    placeholder="Confirm Passsword" 
                    autoComplete="new-password"
                />
            </FormRow>
        </Form>
    )
}

export default PasswordResetForm;
