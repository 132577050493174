import { useState } from "react";
import FormRow from "../../../commmon/FormRow";

type MultipleChoiceQuestionFormProps = Readonly<{
    answers: MultipleChoiceAnswer[]
}>

const MultipleChoiceQuestionForm = (props: MultipleChoiceQuestionFormProps) => {
    const [answers, setAnswers] = useState<MultipleChoiceAnswer[]>(props.answers || []);

    function addNewAnswer() {
        const newAnswers = [...answers, {} as MultipleChoiceAnswer];
        setAnswers(newAnswers);
    }

    return (
        <FormRow className="full">
            <label>Answers</label>
            {
                answers.map((a, i) => {
                    return (
                        <input key={`answer-${i}`} name={`multipleChoiceAnswers[]`} type="text" defaultValue={a.value} />
                    )
                })
            }
            <div>
                <button type="button" onClick={addNewAnswer}>Add Answer</button>
            </div>
        </FormRow>
    )
}

export default MultipleChoiceQuestionForm;
