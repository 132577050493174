import { FormEvent, ReactNode } from "react";
import { useAlert } from "../../context/AlertContext";
import AlertTypeEnum from "../../enum/AlertTypeEnum";
import { apiCall } from "../../functions";
import FormRow from "../commmon/FormRow";
import InputField from "../fields/InputField";
import Form from "../commmon/Form";
import Card from "../commmon/Card";

type CreateUserFormProps = Readonly<{
    onBeforeSubmit?: (formData: GenericObject) => void
    onSubmit?: (e: FormEvent<HTMLFormElement>, formData: GenericObject) => Promise<boolean>
    onCancel?: () => void
    onSuccess?: (user: User, formData: GenericObject) => void,
    children?: ReactNode | ReactNode[]
}>

const CreateUserForm = (props: CreateUserFormProps) => {
    const { setAlertMessage } = useAlert();

    async function onFormSubmit(e: FormEvent<HTMLFormElement>, formData: GenericObject): Promise<boolean> {
        const requiredFields: (keyof User)[] = ["firstName", "lastName", "email", "password"]
        const missingFields: string[] = requiredFields.filter((field) => !formData[field])

        if (missingFields.length > 0) {
            setAlertMessage(`Please provide all the data below: \n\n${missingFields.join(", ")}`, AlertTypeEnum.ERROR);
            return false;
        }
        
        try {
            if (!props.onSubmit) {
                if (props.onBeforeSubmit) {
                    props.onBeforeSubmit(formData);
                }

                const result = await apiCall<User>(`/user/create`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        email: formData.email,
                        password: formData.password
                    })
                });

                setAlertMessage(`An account for ${result.data.firstName} ${result.data.lastName} was created.`, AlertTypeEnum.SUCCESS);
                if (props.onSuccess) {
                    props.onSuccess(result.data, formData);
                }
            }
            else {
                return props.onSubmit(e, formData);
            }
        }
        catch (err) {
            setAlertMessage((err as Error).message, AlertTypeEnum.ERROR)
            return false;
        }
        finally {
            return true;
        }
    }

    return (
        <Card className="display-only">
            <Card.Content>
                <Form 
                    onSubmitClicked={onFormSubmit}
                    onCancelClicked={props.onCancel}
                    showDelete={false}
                >
                    <FormRow>
                        <InputField type="text" required={true} placeholder="First Name" name="firstName" />
                    </FormRow>
                    <FormRow>
                        <InputField type="text" required={true} placeholder="Last Name" name="lastName" />
                    </FormRow>
                    <FormRow className="full">
                        <InputField type="text" required={true} placeholder="Email" name="email" />
                    </FormRow>
                    <FormRow className="full">
                        <InputField type="password" required={true} placeholder="Temporary Password" name="password" />
                    </FormRow>
                    {
                        props.children
                    }
                </Form>
            </Card.Content>
        </Card>
    )
}

export default CreateUserForm;
