import { ReactComponent as CloseIcon } from "../../svgs/cross.svg";

import "../../styles/scss/modal.scss";

type ModalProps = Readonly<{
    onModalClose: () => void
    children: React.ReactNode
}>;

const Modal = function(props: ModalProps) {

    return (
        <div id="modal">
            <div id="modal-background"></div>
            <div id="modal-wrapper">
                <div id="modal-content">
                    <div id="modal-content-close" onClick={props.onModalClose}>
                        <CloseIcon className="icon" />
                    </div>
                    {props.children}
                </div>
            </div>
        </div>
    )

}

export default Modal;