import { ReactComponent as LeagueAppsLogo } from "../../svgs/vendors/LeagueApps.svg";
import Button from "../commmon/Button";

import "../../styles/scss/leagueapps.scss";

type LeagueAppsButtonProps = Readonly<{
    onClick?: () => void
}>

const LeagueAppsButton = (props: LeagueAppsButtonProps) => {

    return (
        <div className="integration leagueapps">
            <Button className="logo" type="button" onClick={props.onClick}>
                <LeagueAppsLogo className="leagueapps"/>
            </Button>
        </div>
    )
}

export default LeagueAppsButton;
