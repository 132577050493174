import { useState } from "react";
import Form from "../../commmon/Form";
import FormRow from "../../commmon/FormRow";
import { useOrgData } from "../../../context/OrgContext";
import SelectField from "../../fields/SelectField";
import { apiCall } from "../../../functions";
import { useAlert } from "../../../context/AlertContext";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";

type CopySeasonQuestionsFormProps = Readonly<{
    onClose?: (e?: React.MouseEvent<HTMLButtonElement>) => void
}>

const CopySeasonQuestionsForm = (props: CopySeasonQuestionsFormProps) => {
    const { setAlertMessage } = useAlert();
    const { season, org } = useOrgData();
    const [selectedSeason, setSelectedSeason] = useState<number>(0);

    async function onSubmitClicked(e: React.FormEvent<HTMLFormElement>, formData: GenericObject): Promise<boolean> {
        if (formData["fromSeason"]) {
            try {
                await apiCall<boolean>(`/awards/copy`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        fromSeason: formData["fromSeason"],
                        toSeason: season?.id
                    })
                })

                setAlertMessage(`Successfully copied sections and questions ${org?.seasons.find(x => x.id === formData["fromSeason"])?.name}`, AlertTypeEnum.SUCCESS);

                if (props.onClose) {
                    props.onClose();
                }

                return true;
            }
            catch(err) {
                setAlertMessage(`Failed to copy data from previous season.`, AlertTypeEnum.ERROR);
                console.error(err);
                return false;
            }
        }
        else {
            return false;
        }
    }

    return (
        <Form onSubmitClicked={onSubmitClicked} onCancelClicked={props.onClose} enableSubmissions={selectedSeason !== 0}>
            <FormRow>
                <SelectField label="From" name="fromSeason" value={selectedSeason} onChange={(e) => setSelectedSeason(Number(e.currentTarget.value))}>
                    {
                        org!.seasons.map((x) => (
                            <option value={x.id}>{x.name} ({x.seasonType.name} - {x.year})</option>
                        ))
                    }
                </SelectField>
            </FormRow>
        </Form>
    )
}

export default CopySeasonQuestionsForm;
