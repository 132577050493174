import {ChangeEvent, useState} from "react";
import clsx from "clsx";

import "../../styles/scss/fields/text-area-field.scss";

type TextAreaFieldProps = Readonly<{
    max: number
    min: number
    name: string
    label?: string
    subLabel?: string
    message?: string
    onChange?: (value: string) => void
}>

const TextAreaField = (props: TextAreaFieldProps) => {
    const characterMax = 3000;
    const [text, setText] = useState<string>(props.message ?? "");

    function onTextChange(e: ChangeEvent<HTMLTextAreaElement>) {
        const newText = e.currentTarget.value
        setText(newText);
        
        if (props.onChange) {
            props.onChange(newText);
        }
    }

    return (
        <div className="textarea-wrapper">
            <div className="textarea-container">
                <label htmlFor={props.name}>{props.label}</label>
                {
                    props.subLabel ? (
                        <sub>{props.subLabel}</sub>
                    ) : null
                }
                <textarea 
                    id={props.name}
                    name={props.name}
                    value={text}
                    onChange={onTextChange}
                />
            </div>
            <div className={clsx(
                "textarea-character-length",
                text.length > characterMax ? "bad" : "good"
            )}>
                Characters {text.length} / {characterMax}
            </div>
        </div>
    )
}

export default TextAreaField;
