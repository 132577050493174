type QuestionRefereeRatingProps = Readonly<{
    json: AdminVoteData<
        Array<RefereeRatingResponse>
    >
}>

type RefereeRatingResponse = Readonly<{
    id: number,
    playerId: number,
    player: Player,
    rawRating: number,
    rating: number,
    votes: number
}>

const QuestionRefereeRating = (props: QuestionRefereeRatingProps) => {
    return (
        <div className="referee-ratings admin">
            <ul className="nominees">
                {
                    props.json.data.map(x => (
                        <li key={x.id} className="nominee">
                            <div className="nominee-name">
                                {x.player.firstName} {x.player.lastName}
                            </div>
                            <div className="nominee-vote">
                                {x.rating.toFixed(2)}%
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default QuestionRefereeRating;