import { Link } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../svgs/home.svg";
import PasswordResetForm from "./forms/PasswordResetForm";

const PasswordReset = () => {

    function onFormSuccess() { }

    return (
        <div id="admin-password-reset">
            <div className="breadcrumb">
                <h2>
                    <Link to="../">
                        <HomeIcon className="icon" />
                    </Link> - Password Reset
                </h2>
            </div>
            <section>
                <PasswordResetForm 
                    onFormSuccess={onFormSuccess}
                />
            </section>
        </div>
    )
}

export default PasswordReset;
