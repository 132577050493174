
export type InputFieldProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { 
    name: string, 
    label?: string 
}

const InputField = (props: InputFieldProps) => {

    return (
        <div className="input-field">
            <label htmlFor={props.name}>{props.label ?? props.placeholder}</label>
            <input id={props.name} {...props} />
        </div>
    )
}

export default InputField;