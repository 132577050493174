import {useState, CSSProperties} from "react";
import Button from "../../commmon/Button";

import "../../../styles/scss/question_text_feedback.scss";

type QuestionTextFeedbackProps = Readonly<{
    json: AdminVoteData<
        Array<AwardPlayerResponse>
    >
}>

const QuestionTextFeedback = function(props: QuestionTextFeedbackProps) {
    const [view, setView] = useState<number>(0);

    function onPrevResponse() {
        const respNumber = view > 0 ? view - 1 : props.json.data.length - 1;
        setView(respNumber);
    }

    function onNextResponse() {
        const respNumber = view < props.json.data.length - 1 ? view + 1 : 0;
        setView(respNumber);
    }

    return (
        <div className="text-feedback">
            {
                props.json.data.length > 0 ? (
                    <>
                        <div className="content">
                            <div className="responses" style={{"--viewing": view} as CSSProperties}>
                                {
                                    props.json.data.map((d) => (
                                        <div key={d.id} className="feedback">
                                            <div>{d.response}</div>
                                            <div className="author">
                                                <div className="author-name">
                                                    - {d.teamPlayer.player?.firstName} {d.teamPlayer.player?.lastName}
                                                </div>
                                                <div>
                                                    {d.teamPlayer.team.name}
                                                </div>
                                                <div className="date">
                                                    {(new Date(d.updated)).toLocaleString("en-us", { month: "long", day: "numeric", year: "numeric" })}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="controls-wrapper">
                            <div className="controls">
                                <Button className="selected" onClick={onPrevResponse}>Last</Button>
                                <div>{view + 1} of {props.json.data.length}</div>
                                <Button className="selected" onClick={onNextResponse}>Next</Button>
                            </div>
                        </div>
                    </>
                ) : (
                    "There are no responses to display"
                )
            }
        </div>
    )
}

export default QuestionTextFeedback;