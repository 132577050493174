enum QuestionTypeEnum {
    TEAMPLAYERS = 1,
    PLAYER = 2,
    TEAMPHOTO = 3,
    TEXT = 4,
    POINTSCALE = 5,
    MESSAGE = 6,
    BESTTEAMPLAYER = 7,
    MULTIPLECHOICE = 8,
    REFEREERATING = 9,
    DIVISION = 14,
    ROOKIEONLY = 15,
    TEAM = 16,
    SUBMISSION = 99999
}

export default QuestionTypeEnum;