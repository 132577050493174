import { useOrgData } from "../../../context/OrgContext";
import NomineeDisplay from "./NomineeDisplay";

type QuestionTeamPhotoProps = Readonly<{
    json: AdminVoteData<
        Array<{
            t_id: number,
            t_name: string,
            votes: number,
            rank: number
        }>
    >
}>

const QuestionTeamPhoto = (props: QuestionTeamPhotoProps) => {
    const { winnersOnly } = useOrgData();

    const winningTeams = props.json.data.filter(x => props.json.winners.some(w => w.teamId === x.t_id));
    let teams = props.json.data;
    
    if (winnersOnly) {
        if (winningTeams.length > 0) {
            teams = winningTeams;
        }
        else {
            teams = props.json.data.slice(0, 1);
        }
    }

    return (
        <div className="nominees">
            {
                teams.map((team, i) => (
                    <NomineeDisplay 
                        key={`${i}`} 
                        nominee={team} 
                        questionId={props.json.questionId}
                        showTeamName={false} 
                        isWinner={props.json.winners.some(x => x.teamId === team["t_id"])}
                    />
                ))
            }
        </div>
    )
}

export default QuestionTeamPhoto;