import { Link } from "react-router-dom";

const AdminNav = function(props: ClickableElement) {

    return (
        <>
            <Link className="link" to="/admin/" onClick={props.onElementClicked}>Admin Portal</Link>
        </>
    )

}

export default AdminNav;