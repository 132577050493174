import clsx from "clsx";
import { useState } from "react";
import { useOrgData } from "../../context/OrgContext";
import Card from "../commmon/Card";
import CopySeasonQuestionsForm from "./forms/CopySeasonQuestionsForm";
import Section from "./Section";

import "../../styles/scss/admin-awards.scss";

const AwardResults = function() {
    const { season, awardData } = useOrgData();
    const [showNonVoters, setShowNonVoters] = useState<boolean>(false);
    const [showSectionForm, setShowSectionForm] = useState<boolean>(false);
    const [showCopyForm, setShowCopyForm] = useState<boolean>(false);

    return (
        <div id="admin-awards">
            <div className="sections">
                {
                    awardData?.awards.length === 0 && (
                        <div className="missing-questions center">
                            <p>No questions exist for this season.</p>
                            <p>To add questions, add a new section and a question. You can also copy questions from another season</p>
                        </div>
                    )
                }
                {
                    awardData?.awards.map((section, i) => (
                        <Section 
                            key={`sectionId-${section.id}-number-${i}`} 
                            section={section}
                        />
                    ))
                }
                {
                    season && (
                        showSectionForm ? (
                            <Section 
                                isDoneEditing={() => setShowSectionForm(false)}
                                section={
                                    { } as AwardSection
                                }
                            />
                        ) : (
                            <Card className="full" onClick={() => setShowSectionForm(true)}>
                                <Card.Header>
                                    <div>Add Section</div>
                                </Card.Header>
                            </Card>
                        )
                    )
                }
            </div>
            <div>
                <Card className="display-only" onClick={() => setShowCopyForm(true)}>
                    <Card.Header className={clsx(!showCopyForm ? "clickable" : "")}>Copy Questions From Season</Card.Header>
                    <Card.Content>
                        {
                            showCopyForm && (
                                <CopySeasonQuestionsForm onClose={(e) => { e?.stopPropagation(); setShowCopyForm(false); }} />
                            )
                        }
                    </Card.Content>
                </Card>
            </div>
            <div className="non-voters">
                <Card 
                    className={`display-only ${showNonVoters ? "expanded" : "collapsed"}`} 
                    onClick={() => setShowNonVoters(!showNonVoters)}
                >
                    <Card.Header>
                        <h2 className="clickable">
                            <div>Non-Voters ({awardData?.nonVoters.totalNonVoters})</div>
                            <div className={`collapsible-menu-button ${showNonVoters ? `expanded` : `collapsed`}`} />
                        </h2>
                    </Card.Header>
                    <Card.Content>
                        <div 
                            className={`question-votes flex wrap ${showNonVoters ? "content-show" : "content-hide"}`} 
                            onClick={(e) => e.stopPropagation()}
                        >
                            {
                                awardData?.nonVoters.teams.map((team) => {
                                    return (
                                        <Card key={`non-voters-team-${team.id}`} className="display-only">
                                            <Card.Header>
                                                <h4>{team.name}</h4>
                                            </Card.Header>
                                            <Card.Content>
                                                {
                                                    team.teamPlayers.map((teamPlayer) => (
                                                        <div key={`non-voters-player-${teamPlayer.id}`}>
                                                            {teamPlayer.player.firstName} {teamPlayer.player.lastName}
                                                        </div>
                                                    ))
                                                }
                                            </Card.Content>
                                        </Card>
                                    )
                                })
                            }
                        </div>
                    </Card.Content>
                </Card>
            </div>
        </div>
    )

}

export default AwardResults;