
const NotFound404 = () => {
    return (
        <div>
            <h1>Page Error 404</h1>
            <p>Ugh ooh...</p>
            <p>Somehow you managed to stumble into the unknown. If you keep getting here, you might want to seek answers from the real world.</p>
            <p><a href="/login">Click here to go back to the login page</a></p>
        </div>
    )
}

export default NotFound404;