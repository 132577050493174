import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Card from "./commmon/Card";
import { apiRequest } from "../functions";
import { useOrgData } from "../context/OrgContext";

import "../styles/scss/season-select.scss";

const SeasonSelect = function() {
    const { loadData } = useOrgData();
    const nav = useNavigate();
    const { player } = useAuth();
    const [teamPlayers, setTeamPlayers] = useState<TeamPlayers[]>([]);

    useEffect(() => {
        if (!player?.id) {
            nav("/login");
        }
        else {
            apiRequest<TeamPlayers[]>(`/teamplayers/player/?playerId=${player.id}`)
            .then((json) => {
                const playerSeasons: TeamPlayers[] = [];

                json.data?.forEach((teamPlayer) => {
                    if (teamPlayer.season.current) {
                        playerSeasons.push(teamPlayer);
                    }
                });
    
                setTeamPlayers(playerSeasons);
            })
        }
    }, [player]);

    function onSeasonClicked(teamPlayer: TeamPlayers) {
        loadData(teamPlayer.season.organizationId, teamPlayer.seasonId);
        nav(`/season/vote`, {
            state: teamPlayer
        });
    }

    return (
        <div id="season-select">
            <h2>Season Selection</h2>
            {
                teamPlayers.length > 0 ? (
                    teamPlayers.map(teamPlayer => (
                        <Card key={teamPlayer.id} onClick={() => onSeasonClicked(teamPlayer)} className="seasonal-player">
                            <aside className="org-logo">
                                <img src={`/organization/${teamPlayer.season.organization.id}/photo`} alt={`${teamPlayer.season.organization.name} logo`} />
                            </aside>
                            <div className="card-wapper">
                                <Card.Header>
                                    <h3>
                                        {teamPlayer.season.name}
                                    </h3>
                                </Card.Header>
                                <Card.Content>
                                    <div className="center">
                                        {teamPlayer.team?.name}
                                    </div>
                                </Card.Content>
                                <Card.Footer>
                                    <div>
                                        {
                                            `${teamPlayer.season.seasonType.name}
                                            ${teamPlayer.season.sport.name}
                                            ${teamPlayer.season.year}
                                            Season`
                                        }
                                    </div>
                                </Card.Footer>
                            </div>
                        </Card>
                    ))
                ) : (
                    <div>
                        You are currently not in a season where voting is active.
                    </div>
                )
            }
        </div>
    )

}

export default SeasonSelect;