import { ReactNode, createContext, useContext, useEffect, useState } from "react"
import { apiCall } from "../functions"

type SiteInfoProviderProps = Readonly<{
    children: ReactNode
}>

type SiteInfoContextProps = Readonly<{
    version: string
    db: string
}>

const SiteInfoContext = createContext({} as SiteInfoContextProps)

export function SiteInfoProvider({ children }: SiteInfoProviderProps) {
    const [version, setVersion] = useState<string>("");
    const [db, setDbTarget] = useState<string>("");

    useEffect(() => {
        apiCall<GenericObject>(`/app/info`)
        .then((resp) => {
            setVersion(resp.data.version);
            if (resp.data.db) {
                setDbTarget(resp.data.db);
            }
        })
    }, []);

    return (
        <SiteInfoContext.Provider value={{
            version,
            db
        }}>
            {children}
        </SiteInfoContext.Provider>
    )
}

export function useSiteInfo() {
    return useContext(SiteInfoContext);
}