import { FormEvent, useState } from "react";
import { DndContext, closestCenter, DragEndEvent } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, arrayMove } from "@dnd-kit/sortable";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers"
import Form, { IFormCallback } from "../../commmon/Form"
import FormRow from "../../commmon/FormRow";
import CheckboxField from "../../fields/CheckboxField";
import InputField from "../../fields/InputField";
import DraggableElement from "../../commmon/DraggableElement";
import { apiCall, apiRequest } from "../../../functions";
import { useOrgData } from "../../../context/OrgContext";
import { useAlert } from "../../../context/AlertContext";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";

type SectionFormProps = Readonly<{
    section: Partial<AwardSection>
}>

const SectionForm = (props: SectionFormProps & IFormCallback) => {
    const { setAlertMessage } = useAlert();
    const { season } = useOrgData();
    const [questionOrder, setQuestionOrder] = useState<number[]>(props.section.questions?.map(x => x.id) || []);

    function onDragEnd(e: DragEndEvent) {
        const { active, over } = e;
        
        if (active.id !== over?.id) {
            setQuestionOrder((order) => {
                const oldIndex = order.indexOf(Number(active.id));
                const newIndex = order.indexOf(Number(over?.id));
                return arrayMove(order, oldIndex, newIndex);
            })
        }
    }

    async function onFormSubmit(e: FormEvent<HTMLFormElement>, formData: GenericObject) {
        try {
            const result = await apiRequest<AwardSection>(`/awards/sections/save`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    section: {
                        id: props.section.id,
                        seasonId: props.section.seasonId || season?.id,
                        released: formData["released"],
                        locked: formData["locked"],
                        title: formData["sectionTitle"],
                    }
                })
            });

            if (result.success) {
                // Go save the questions now
                const reorderRequest = await apiRequest<AwardQuestion[]>(`/awards/questions/reorder`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        sectionId: result.data?.id,
                        questionIds: questionOrder
                    })
                })
                
                setAlertMessage(result.message, AlertTypeEnum.SUCCESS);
                props.onFormSuccess();
            }
            else {
                setAlertMessage(result.message, AlertTypeEnum.ERROR);
                // setInvalidFields(json.fields);
            }
        }
        catch (err) {
            console.error(err);
            setAlertMessage((err as Error).message, AlertTypeEnum.ERROR);
            if (props.onFormError) {
                props.onFormError();
            }
            return false;
        }
        finally {
            return true;
        }
    }

    async function onFormDelete(): Promise<boolean> {
        try {
            if (window.confirm("Are you sure you wish to delete this section?")) {
                const result = await apiCall<ApiResponse>(`/awards/sections/delete`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        sectionId: props.section.id
                    })
                })

                if (result.data.success) {
                    setAlertMessage(result.data.message, AlertTypeEnum.SUCCESS);
                    props.onFormSuccess();
                }
                else {
                    setAlertMessage(result.data.message, AlertTypeEnum.ERROR);
                }
            }
        }
        catch (err) {
            console.error(err);
            setAlertMessage((err as Error).message, AlertTypeEnum.ERROR);
            if (props.onFormError) {
                props.onFormError();
            }
            return false;
        }
        finally {
            return true;
        }
    }

    return (
        <div id="admin-form section">
            <Form 
                onSubmitClicked={onFormSubmit}
                onDeleteClicked={onFormDelete}
                onCancelClicked={props.onFormCancel}
                showDelete={props.section?.id !== undefined}
            >
                <FormRow className="full">
                    <InputField 
                        type="text" 
                        name="sectionTitle" 
                        placeholder="Section Name" 
                        defaultValue={props.section.title} 
                    />
                </FormRow>
                <FormRow>
                    <CheckboxField 
                        label="Released" 
                        checked={props.section.released || false} 
                        type="switch" 
                        name="released"
                    />
                </FormRow>
                <FormRow>
                    <CheckboxField 
                        label="Locked" 
                        checked={props.section.locked || false} 
                        type="switch" 
                        name="locked"
                    />
                </FormRow>
                {
                    props.section.questions ? (
                        <FormRow className="full">
                            <h3>Question Order</h3>
                            <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd} modifiers={[restrictToParentElement, restrictToVerticalAxis]}>
                                <SortableContext 
                                    items={questionOrder}
                                    strategy={verticalListSortingStrategy}
                                >
                                    {
                                        questionOrder.map((id) => {
                                            const question = props.section.questions?.find(x => x.id === Number(id))
                                            
                                            return question ? (
                                                <DraggableElement key={question.id} id={id}>
                                                    {question.text}
                                                </DraggableElement>
                                            ) : null
                                        })
                                    }
                                </SortableContext>
                            </DndContext>
                        </FormRow>
                    ) : null
                }
            </Form>
        </div>
    )
}

export default SectionForm;