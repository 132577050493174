import { MouseEvent, useState } from "react"

import "../../styles/scss/checkbox-field.scss";

type CheckboxFieldProps = Readonly<{
    type: "checkbox" | "switch"
    label: string
    checked: boolean
    name: string
    onClick?: (checked: boolean) => void
}>

const CheckboxField = (props: CheckboxFieldProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(props.checked);

    function onClick(e: MouseEvent<HTMLInputElement>) {
        const newValue = !isChecked;
        setIsChecked(newValue)

        if (props.onClick) {
            props.onClick(newValue);
        }
    }

    return (
        <div className="checkbox-wrapper">
            {
                props.type === "switch" ? (
                    <label htmlFor={`checkbox-${props.label}`}>
                        {props.label}
                    </label>
                ) : null
            }
            <div className={`checkbox-field ${props.type}`} onClick={onClick}>
                <input 
                    type="checkbox" 
                    id={`checkbox-${props.label}`}
                    checked={isChecked} 
                    onChange={() => {}}
                    name={props.name}
                /> 
                <span className={`checkbox-display ${isChecked ? "checked" : ""}`} />
            </div>
            {
                props.type === "checkbox" ? (
                    <label htmlFor={`checkbox-${props.label}`}>
                        {props.label}
                    </label>
                ) : null
            }
        </div>
    )
}

export default CheckboxField;