import CreateUserForm from "../forms/CreateUserForm";


const CreateAccount = () => {

    function onFormEmptyHandle(user: User) { }

    return (
        <div>
            <CreateUserForm onSuccess={onFormEmptyHandle}/>
        </div>
    )
}

export default CreateAccount;
