import {useState, useEffect} from "react";
import { useOrgData } from "../../context/OrgContext";
import QuestionVotes from "./QuestionVotes";
import ProgressBar from "../commmon/ProgressBar";
import Tooltip from "../commmon/Tooltip";
import QuestionForm from "./forms/QuestionForm";
import { ReactComponent as EditIcon } from "../../svgs/pencil.svg";

import "../../styles/scss/admin-question.scss";

type QuestionProps = Readonly<{
    section: AwardSection
    question: AwardQuestion
    onQuestionCancel: () => void
}>;

const Question = function(props: QuestionProps) {
    const { awardData, reloadAwardData } = useOrgData();
    const [isEditQuestion, setIsEditQuestion] = useState<AwardQuestion|boolean>(false);
    const voteData = awardData?.votes.find(x => x.questionId === props.question.id);

    useEffect(() => {
        if (!props.question?.id) {
            setIsEditQuestion(true);
        }
    }, [props.question.questionTypeId, props.question.id]);

    function onFormSuccess() {
        props.onQuestionCancel();
        setIsEditQuestion(false);
        reloadAwardData();
    }

    function onFormCancel() {
        props.onQuestionCancel();
        setIsEditQuestion(false);
    }

    return (
        <div className="admin-questions">
            <div className="question">
                {
                    isEditQuestion ? (
                        <QuestionForm 
                            section={props.section}
                            question={props.question} 
                            onFormSuccess={onFormSuccess}
                            onFormCancel={onFormCancel}
                        />
                    ) : (
                        <>
                            <header>
                                <Tooltip>
                                    <ProgressBar 
                                        percent={voteData?.votePercent || 0} 
                                        legend={props.question.text} 
                                    />
                                    <Tooltip.Content>
                                        <div>{voteData?.votePercent || 0}%</div>
                                    </Tooltip.Content>
                                </Tooltip>
                                <EditIcon className="icon" onClick={() => setIsEditQuestion(true)} />
                            </header>
                            <div className="question-info">
                                <div className="votes">
                                    {
                                        voteData ? (
                                            <QuestionVotes question={props.question} voteData={voteData} />
                                        ) : null
                                    }
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )

}

export default Question;