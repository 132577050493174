import FormRow from "../../../commmon/FormRow";
import SelectField from "../../../fields/SelectField";

type BestTeamPlayerQuestionFormProps = Readonly<{
    fromQuestion: number,
    questions: AwardQuestion[]
}>

const BestTeamPlayerQuestionForm = (props: BestTeamPlayerQuestionFormProps) => {
    return (
        <FormRow>
            <SelectField name="fromQuestion" value={props.fromQuestion} label="Nominated players from what question:">
                {
                    props.questions.map(q => <option key={q.id} value={q.id}>{q.text}</option>)
                }
            </SelectField>
        </FormRow>
    )
}

export default BestTeamPlayerQuestionForm;
