import React, {MouseEvent, useState} from "react";

import "../../styles/scss/tooltip.scss";

type TooltipProps = Readonly<{ children: JSX.Element | Array<JSX.Element> }>
type TooltipContentProps = Readonly<{ children: JSX.Element | Array<JSX.Element> }>

const Tooltip = function(props: TooltipProps) {
    let toolTipTimeOut: NodeJS.Timeout;
    const [showToolTip,  setShowToolTip] = useState<boolean>(false);

    function onMouseEnter() {
        clearTimeout(toolTipTimeOut);
        toolTipTimeOut = setTimeout(() => {
            setShowToolTip(true);
        }, 100);
    }

    function onMouseLeave() {
        clearTimeout(toolTipTimeOut);
        setShowToolTip(false);
    }

    const content = React.Children.map(props.children, child => child.type.displayName !== "TooltipContent" ? child : null)
    const tooltipElement = React.Children.map(props.children, child => child.type.displayName === "TooltipContent" ? child : null);

    return (
        <div 
            className="tooltip-wrapper" 
            onMouseEnter={onMouseEnter} 
            onMouseLeave={onMouseLeave}
        >
            {content}
            {
                showToolTip ? (
                    <div className="tooltip-content">
                        {tooltipElement}
                    </div>
                ) : null
            }
        </div>
    )

}

const Content = ({ children }: TooltipContentProps) => <>{children}</>
Content.displayName = "TooltipContent";
Tooltip.Content = Content

export default Tooltip;