import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Form from "./commmon/Form";
import FormRow from "./commmon/FormRow";
import InputField from "./fields/InputField";
import { useAlert } from "../context/AlertContext";
import AlertTypeEnum from "../enum/AlertTypeEnum";

import "../styles/scss/login.scss";

const Login = function() {
    const { user, player, login } = useAuth();
    const navigate = useNavigate();
    const { setAlertMessage } = useAlert();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    useEffect(() => {
        if (user || player) {
            returnUserBackToLastPage();
        }
    }, [user, player])

    function returnUserBackToLastPage() {
        let goto = location.state?.from?.pathname;

        if (user) {
            goto = user.canReset ? "/org/user/resetforce/" : "/dashboard/"
        }
        else if (!user && player) {
            goto = "/season/"
        }

        navigate(goto);
    }

    async function onLoginFormSubmit(e: FormEvent<HTMLFormElement>, formData: GenericObject): Promise<boolean> {
        setIsLoading(true);

        try {
            const result = await login(formData.email, formData.pass);

            if (result.isUser) {
                if (result.showPassword) {
                    setShowPassword(true);
                    return true;
                }
                else {
                    if (!result.success) {
                        setAlertMessage("The email or password you provided is invalid.", AlertTypeEnum.ERROR);
                        return false;
                    }
                }
            }
            else if (result.isPlayer && !result.isUser) {
                if (!result.success) {
                    setAlertMessage("The email you provided is invalid.", AlertTypeEnum.ERROR);
                    return false;
                }
            }
            else {
                setAlertMessage("The email you provided is invalid.", AlertTypeEnum.ERROR);
                return false;
            }

            return true;
        }
        catch(err) {
            console.error(err);
            setAlertMessage((err as Error).message, AlertTypeEnum.ERROR);
            return false;
        }
        finally {
            return true;
        }
    }

    return (
        <div id="login">
            <div className="prompt">
                <Form 
                    onSubmitClicked={onLoginFormSubmit}
                    submitButtonText="Login"
                    hideCancel
                >
                    <FormRow className="full">
                        <InputField 
                            name="email"
                            type="text"
                            placeholder="Email"
                            autoComplete="email"
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            value={email}
                        />
                    </FormRow>
                    <FormRow className={`full ${showPassword ? "show" : "hide"}`}>
                        {
                            showPassword ? (
                                <InputField 
                                    name="pass"
                                    type="password"
                                    placeholder="Password"
                                    autoComplete="password"
                                    onChange={(e) => setPassword(e.currentTarget.value)}
                                    value={password}
                                />
                                ) : <div/>
                            }
                    </FormRow>
                </Form>
            </div>
        </div>
    )
}

export default Login;