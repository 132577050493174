import { createContext, ReactNode, useContext, useState } from "react"
import AlertTypeEnum from "../enum/AlertTypeEnum"
import useDebounce from "../hooks/useDebounce"

import "../styles/scss/alert-popup.scss";

type AlertProviderProps = {
    children: ReactNode
}

type AlertContextProps = {
    setAlertFromApiMessage: <T>(apiResponse: ApiDataResponse<T>) => void
    setAlertMessage: (message: string, type: AlertTypeEnum, time?: number) => void
}

const AlertContext = createContext({} as AlertContextProps);

export function AlertProvider({ children }: AlertProviderProps) {
    const debounce = useDebounce(clearMessage, 4000);
    const [alert, setAlert] = useState<{
        message: string,
        type: AlertTypeEnum
    } | undefined>()

    function setAlertFromApiMessage<T>(response: ApiDataResponse<T>) {
        if (!response.message) {
            console.debug("No message was part of the ApiResponse. Not showing an alert.");
        }
        else {
            setAlertMessage(response.message, response.success ? AlertTypeEnum.SUCCESS : AlertTypeEnum.ERROR);
        }
    }

    function setAlertMessage(message: string, type: AlertTypeEnum): void {
        debounce();
        setAlert({
            message,
            type
        });
    }

    function clearMessage() {
        setAlert(undefined);
    }

    return <AlertContext.Provider value={{
        setAlertFromApiMessage,
        setAlertMessage
    }}>
        {children}
        <div className={`alert-popup ${alert ? "show" : "hide"} ${alert?.type !== undefined ? AlertTypeEnum[alert.type].toString().toLowerCase() : ""}`}>
            {alert?.message}
        </div>
    </AlertContext.Provider>
}

export function useAlert() {
    return useContext(AlertContext);
}