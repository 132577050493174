import { useOrgData } from "../../../context/OrgContext";
import Card from "../../commmon/Card";
import ProgressBar from "../../commmon/ProgressBar";
import Tooltip from "../../commmon/Tooltip";
import NomineeDisplay from "./NomineeDisplay";

import "../../../styles/scss/admin-team-votes.scss";

type QuestionTeamVotesProps = Readonly<{
    json: AdminVoteData<
        Array<{
            team: Partial<Team> & { totalPlayers: number, totalVotes: number, percVoted: number },
            players: Array<{
                "tp_id": number,
                "tp_playerId": number,
                "tp_teamId": number,
                "p_firstName": string,
                "p_lastName": string,
                "p_aka": string,
                "t_id": number,
                "t_name": string,
                "votes": number,
                "rank": number
            }>
        }>
    >
}>;

const QuestionTeamVotes = function(props: QuestionTeamVotesProps) {
    const { winnersOnly } = useOrgData();

    return (
        <div className="team-votes">
            {
                props.json.data.map(team => {
                    const winningPlayers = team.players.filter(x => props.json.winners.some(w => w.teamPlayersId === x.tp_id));
                    let players = team.players;
                    
                    if (winnersOnly) {
                        if (winningPlayers.length > 0) {
                            players = winningPlayers;
                        }
                        else {
                            players = team.players.slice(0, 1);
                        }
                    }

                    return (
                        <Card key={team.team.id} className="display-only">
                            <Card.Header>
                                <Tooltip>
                                    <ProgressBar 
                                        legend={team.team.name!} 
                                        percent={team.team.percVoted} 
                                    />
                                    <Tooltip.Content>
                                        <div>
                                            {team.team.totalVotes} / {team.team.totalPlayers}
                                            <br/>
                                            {team.team.percVoted}%
                                        </div>
                                    </Tooltip.Content>
                                </Tooltip>
                            </Card.Header>
                            <Card.Content>
                                <div className="nominees">
                                    {
                                        players.map((teamPlayer, i) => (
                                            <NomineeDisplay 
                                                key={`${i}`} 
                                                nominee={teamPlayer} 
                                                questionId={props.json.questionId}
                                                showTeamName={false} 
                                                isWinner={props.json.winners.some(x => x.teamPlayersId === teamPlayer["tp_id"])}
                                            />
                                        ))
                                    }
                                </div>
                            </Card.Content>
                        </Card>
                    )
                })
            }
        </div>
    )
}

export default QuestionTeamVotes;
