import CheckboxField from "../../../fields/CheckboxField";

type SelfVoteProps = Readonly<{
    allowSelfVote?: boolean
}>

export function SelfVote(props: SelfVoteProps) {
    return (
        <CheckboxField 
            type="switch" 
            name="questionSelfVote" 
            label="Can players vote for themselves?" 
            checked={props.allowSelfVote ?? false} 
        />
    )
}