import { ReactNode } from "react";
import clsx from "clsx";

type FormRowProps = Readonly<{
    children: ReactNode
    className?: string
}>

const FormRow = (props: FormRowProps) => {
    return (
        <div className={clsx("form-row", props.className)}>
            {props.children}
        </div>
    )
}

export default FormRow;