import clsx from "clsx";
import { ReactNode, useState } from "react";

import "../../styles/scss/card.scss";

type CardProps = Readonly<{
    className?: string
    onClick?: () => void
    isActive?: boolean
    children: ReactNode
    isCollapsible?: boolean
}>
type SubCardProps = Readonly<{ children: ReactNode, className?: string }>;

const Card = function(props: CardProps) {
    const [collapsed, setCollapsed] = useState<boolean>(false) 

    function onClick() {
        if (props.isCollapsible) {
            setCollapsed(!collapsed);
        }
        
        if (props.onClick) {
            props.onClick();
        }
    }

    return (
        <div 
            className={clsx(
                "button",
                "card", 
                props.isActive ? "active" : "not-active", 
                props.className
            )} 
            onClick={onClick}
        >
            {props.children}
        </div>
    )
}

const CardHeader = (props: SubCardProps) => (
    <div className={clsx("card-header", props.className)}>
        {props.children}
    </div>
)

const CardContent = (props: SubCardProps) => (
    <div className={clsx("card-content", props.className)}>{props.children}</div>
)

const CardFooter = (props: SubCardProps) => (
    <div className={clsx("card-footer", props.className)}>{props.children}</div>
)

Card.Header = CardHeader;
Card.Content = CardContent;
Card.Footer = CardFooter;

export default Card;