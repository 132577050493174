import { useEffect, useState } from "react";
import { useOrgData } from "../../../context/OrgContext";
import { useAlert } from "../../../context/AlertContext";
import { apiCall } from "../../../functions";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";
import Loading from "../../commmon/Loading";
import Card from "../../commmon/Card";
import clsx from "clsx";
import Modal from "../../commmon/Modal";
import Button from "../../commmon/Button";

type LeagueLabModalProps = Readonly<{
    onClose: () => void
}>

const LeagueLabModal = (props: LeagueLabModalProps) => {
    const { org, season } = useOrgData();
    const { setAlertMessage } = useAlert();
    const [leagues, setLeagues] = useState<Array<any>>([]);
    const [selectedLeague, setSelectedLeague] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        apiCall<any[]>(`/integration/leaguelab/leagues?orgId=${org?.id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(resp => {
            if (resp.resp.ok) {
                setLeagues(resp.data);
            }
        })
        .catch((err) => {
            setAlertMessage(err.message, AlertTypeEnum.ERROR);
            console.log(err);
        })
        .finally(() => setIsLoading(false))
    }, [])

    function onImportProgramButtonClicked() {
        if (selectedLeague) {
            setIsLoading(true);

            apiCall(`/integration/leaguelab/import`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    orgId: org?.id,
                    seasonId: season?.id,
                    leagueId: selectedLeague
                })
            })
            .then((resp) => {
                if (resp.data) {
                    setAlertMessage(`The season was successfully imported.`, AlertTypeEnum.SUCCESS);
                    props.onClose();
                }
                else {
                    console.warn(resp);
                    setAlertMessage(`An invalid message was received.`, AlertTypeEnum.WARNING);
                }
            })
            .catch((err) => {
                console.error(err);
                setAlertMessage(err.message, AlertTypeEnum.ERROR);
            })
            .finally(() => setIsLoading(false))
        }
    }

    return (
        <Modal onModalClose={props.onClose}>
            <h3 className="center">Import League</h3>
            {
                isLoading && (
                    <Loading />
                )
            }
            {
                leagues.map((league) => (
                    <Card 
                        key={league.id} 
                        className={clsx(league.id === selectedLeague ? "selected": "")} 
                        onClick={() => setSelectedLeague(league.id)}
                    >
                        <Card.Header>{league.name}</Card.Header>
                        <Card.Footer>{league.status}</Card.Footer>
                    </Card>
                ))
            }
            <div className="flex">
                <Button
                    className="full active"
                    onClick={props.onClose}
                >
                    Close
                </Button>
                <Button
                    className="full active"
                    type="button"
                    disabled={!selectedLeague}
                    onClick={onImportProgramButtonClicked}
                >
                    Import League Data
                </Button>
            </div>
        </Modal>
    )
}

export default LeagueLabModal;
