import { Link } from "react-router-dom";
import SettingsForm from "./forms/SettingsForm";
import { ReactComponent as HomeIcon } from "../../svgs/home.svg";

import "../../styles/scss/award-certificate.scss";

const OrgSettings = () => {
    return (
        <div>
            <h3>
                <Link to="../">
                    <HomeIcon className="icon" />
                </Link>
                Organization Settings
            </h3>
            <SettingsForm />
        </div>
    )
}

export default OrgSettings;
