import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

type RequireAuthProps = Readonly<{
    children: JSX.Element
}>;

const RequireAuth = function(props: RequireAuthProps) {
    const { user, player } = useAuth();
    const location = useLocation();

    if (!user && !player) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    return props.children;
}

export default RequireAuth;