import { FormEvent } from "react";
import Form, { IFormCallback } from "../../commmon/Form";
import FormRow from "../../commmon/FormRow";
import InputField from "../../fields/InputField";
import SelectField from "../../fields/SelectField";
import { apiRequest } from "../../../functions";
import { useAlert } from "../../../context/AlertContext";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";

type TeamFormProps = Readonly<{
    seasonId?: number | string
    team?: Team
}>

const TeamForm = (props: TeamFormProps & IFormCallback) => {
    const { setAlertMessage } = useAlert();

    async function onFormSubmit(e: FormEvent<HTMLFormElement>, formData: GenericObject): Promise<boolean> {

        try {
            const result = await apiRequest<Team>(`/teams/save`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    team: {
                        id: props.team?.id,
                        seasonId: props.team?.seasonId || props.seasonId,
                        name: formData["teamName"],
                        division: formData["teamDivision"]
                    }
                })
            })

            if (result.success && result.data) {
                setAlertMessage(result.message, AlertTypeEnum.SUCCESS);
                props.onFormSuccess();
            }
            else if (result.fields) {
                // setInvalidFields(result.fields);
                console.warn(result.message);
                setAlertMessage(result.message, AlertTypeEnum.ERROR);
            }
        }
        catch (err) {
            console.error(err);
            setAlertMessage(`An error occured when saving the team`, AlertTypeEnum.ERROR);
            if (props.onFormError) {
                props.onFormError();
            }
            return false;
        }
        finally {
            return true;
        }
    }

    async function onFormDelete(): Promise<boolean> {
        try {
            if (window.confirm(`Are you sure you want to delete team '${props.team?.name}'?`)) {
                const result = await apiRequest(`/teams/delete`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        id: props.team?.id,
                        seasonId: props.team?.seasonId
                    })
                })

                if (result.success) {
                    setAlertMessage(result.message, AlertTypeEnum.SUCCESS);
                    props.onFormSuccess();
                }
                else {
                    setAlertMessage(`An error occured when deleting the team`, AlertTypeEnum.ERROR);
                    console.error(result.message);
                }
            }
        }
        catch (err) {
            console.error(err);
            setAlertMessage(`An error occured when deleting the team`, AlertTypeEnum.ERROR);
            if (props.onFormError) {
                props.onFormError();
            }
            return false;
        }
        finally {
            return true;
        }
    }

    return (
        <Form 
            onSubmitClicked={onFormSubmit}
            onDeleteClicked={onFormDelete}
            onCancelClicked={props.onFormCancel}
            showDelete={props.team?.id !== undefined}
        >
            <FormRow className="full">
                <InputField
                    type="text"
                    name="teamName"
                    placeholder="Team Name"
                    defaultValue={props.team?.name}
                />
            </FormRow>
            <FormRow>
                <SelectField
                    name="teamDivision"
                    label="Division"
                    defaultValue={props.team?.division}
                >
                    <option value={""}></option>
                    <option value={1}>SOCIAL</option>
                    <option value={2}>INTERMEDIATE</option>
                    <option value={3}>ADVANCED</option>
                </SelectField>
            </FormRow>
        </Form>
    )
}

export default TeamForm;
