import { ChangeEvent, useEffect, useState } from "react";
import "../../styles/scss/answer-referee-rating.scss";

type RefereeRatingProps = Readonly<{
    question: AwardQuestion
    answers: TeamPlayers[]
    currentValue?: AwardPlayerResponse
    onDecisionMade: (data: string) => void
}>

const RefereeRating = (props: RefereeRatingProps) => {
    const [labels, setLabels] = useState<Array<number>>([])
    const [ratings, setRatings] = useState<Array<{id: number, rating: number}>>([]);
    
    useEffect(() => {
        const labelData = [];
        for (let i = props.question.min; i <= props.question.max; i++) {
            labelData.push(i);
        }

        if (props.currentValue) {
            const jsonData = JSON.parse(props.currentValue.response as string || "[]");
            setRatings(jsonData);
        }

        setLabels(labelData);
    }, []);

    function onRatingChanged(e: ChangeEvent<HTMLInputElement>) {
        const updatedRatings = [...ratings];
        let refRating = updatedRatings.find(x => x.id === Number(e.currentTarget.getAttribute("data-refid")));
        if (!refRating) {
            refRating = {
                id: Number(e.currentTarget.getAttribute("data-refid")),
                rating: 1
            };
            updatedRatings.push(refRating);
        }
        refRating.rating = Number(e.currentTarget.value);
        setRatings(updatedRatings);
        props.onDecisionMade(JSON.stringify(updatedRatings));
    }

    return (
        <div id="referee-ratings">
            <ul>
                {
                    props.answers.map(referee => {
                        return (
                            <li key={`ref-id-${referee.id}`}>
                                <div className="referee-name">
                                    {referee.player.firstName} {referee.player.lastName}
                                </div>
                                <div className="referee-rating-scale">
                                    {/* <div className="side-label">
                                        Needs Work
                                    </div> */}
                                    <div className="point-scale">
                                        <input 
                                            data-refid={referee.id}
                                            id="scale"
                                            type="range" 
                                            min={props.question.min} 
                                            max={props.question.max} 
                                            step={1}
                                            value={ratings.find(x => x.id === referee.id)?.rating || props.question.min}
                                            onChange={onRatingChanged}
                                        />
                                        <div className="ticks">
                                            {
                                                labels.map((x) => {
                                                    return (
                                                        <span key={`ref-${referee.id}-label-${x}`} className="tick">
                                                            {x}
                                                        </span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    {/* <div className="side-label">
                                        Perfect
                                    </div> */}
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default RefereeRating;