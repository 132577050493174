import {CSSProperties} from "react";

import "../../styles/scss/progress-bar.scss";

type ProgressBarProps = Readonly<{
    percent: number,
    legend: string
}>;

const ProgressBar = function(props: ProgressBarProps) {
    return (
        <div className="progress-bar-container">
            <div className="progress-bar" style={{
                "--progress": props.percent+"%"
            } as CSSProperties} />
            <div className="legend">{props.legend}</div>
        </div>
    )
}

export default ProgressBar;