import {ChangeEvent, useState} from "react";

import "../../styles/scss/text-response.scss";

type TextAnswerProps = Readonly<{
    question: AwardQuestion
    currentValue?: AwardPlayerResponse
    onDecisionMade: (value: string, hasError?: boolean) => void
}>;

const TextAnswer = function(props: TextAnswerProps) {
    const characterMax = 3000;
    const [text, setText] = useState<string>(props.currentValue?.response as string || "");

    function onTextChange(e: ChangeEvent<HTMLTextAreaElement>) {
        const newText = e.currentTarget.value
        setText(newText);
        props.onDecisionMade(newText, newText.length > characterMax);
    }

    return (
        <div className={`text-response ${text.length > characterMax ? "bad" : "good"}`}>
            <div className="textarea-container">
                <textarea 
                    onChange={onTextChange}
                    value={text}
                />
            </div>
            <div className="textarea-footer">
                Characters {text.length} / {characterMax}
            </div>
        </div>
    )

}

export default TextAnswer;