import { FormEvent, useEffect, useState } from "react";
import { ReactComponent as LeagueAppsLogo } from "../../../svgs/vendors/LeagueApps.svg";
import Card from "../../commmon/Card";
import Form from "../../commmon/Form";
import InputField from "../../fields/InputField";
import FormRow from "../../commmon/FormRow";
import { apiCall } from "../../../functions";
import { useAlert } from "../../../context/AlertContext";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";
import FileUploadField from "../../fields/FileUploadField";
import { useOrgData } from "../../../context/OrgContext";

const LeagueApps = () => {
    const { org } = useOrgData();
    const { setAlertMessage } = useAlert();
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [integrationData, setIntegrationData] = useState<LeagueAppsConnectionData | undefined>();

    useEffect(() => {
        if (showDetails) {
            apiCall<Integration>(`/integration/leagueapps/info`)
            .then((resp) => {
                if (resp.data.data) {
                    setIntegrationData(JSON.parse(resp.data.data || "") || undefined)
                }
            })
            .catch((err) => {
                console.warn(err);
            });
        }
    }, [showDetails])

    async function onSaveConnectionData(e: FormEvent<HTMLFormElement>, formData: GenericObject): Promise<boolean> {
        if (!formData.apiKey || !formData.siteId) {
            setAlertMessage("Unable to save. Please provide all data.", AlertTypeEnum.ERROR)
        }

        try {
            const form: FormData = new FormData();
            form.append("orgId", org?.id.toString() || "");
            form.append("siteId", formData["siteId"]);
            form.append("apiKey", formData["apiKey"]);
            form.append("file", formData["pemFile"]);

            const result = await apiCall<Integration>(`/integration/leagueapps/save`, {
                method: "POST",
                body: form
            })

            setAlertMessage("Saved connection information", AlertTypeEnum.SUCCESS);
            setIntegrationData(JSON.parse(result.data.data || ""));
            setShowDetails(false);
        }
        catch (err) {
            console.error(err);
            setAlertMessage("Unable to save connection info", AlertTypeEnum.ERROR);
            return false;
        }
        finally {
            return true;
        }
    }

    return (
        <div className="integration leagueapps">
            <Card className={showDetails ? "display-only" : ""}>
                <Card.Content>
                    <div className="logo" onClick={() => setShowDetails(true)}>
                        <LeagueAppsLogo className="leagueapps" />
                    </div>
                    {
                        showDetails ? (
                            <Form 
                                onSubmitClicked={onSaveConnectionData}
                                onCancelClicked={() => setShowDetails(false)}
                            >
                                <FormRow className="full">
                                    <InputField type="text" placeholder="Site ID" name="siteId" defaultValue={integrationData?.siteId} autoComplete="none" />
                                </FormRow>
                                <FormRow className="full">
                                    <InputField type="password" placeholder="API Key" name="apiKey" defaultValue={integrationData?.apiKey} autoComplete="none" />
                                </FormRow>
                                <FormRow className="full">
                                    <FileUploadField name="pemFile" label="PEM file" accept=".pem" />
                                </FormRow>
                            </Form>
                        ) : null
                    }
                </Card.Content>
            </Card>
        </div>
    )
}

export default LeagueApps;