import { Link } from "react-router-dom";
import Button from "../commmon/Button";

const Home = () => {
    return (
        <div id="admin-home">
            <h2>
                Admin Home
            </h2>
            <section>
                <Link to="organizations">
                    <Button className="full">
                        <h3>Organization View</h3>
                    </Button>
                </Link>
            </section>
        </div>
    )
}

export default Home;
