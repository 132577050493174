import { TeamDivision } from "../../../enum/TeamDivision";
import { useOrgData } from "../../../context/OrgContext";
import Card from "../../commmon/Card";
import NomineeDisplay from "./NomineeDisplay";

import "../../../styles/scss/admin-question-division.scss";

type QuestionDivisionProps = Readonly<{
    json: AdminVoteData<{
        [division: string]: Array<{
            "tp_id": number,
            "tp_playerId": number,
            "tp_teamId": number,
            "p_firstName": string,
            "p_lastName": string,
            "p_aka": string,
            "t_name": string,
            "t_division": string,
            "votes": string,
            "rank": number
        }>
    }>
}>

const QuestionDivision = (props: QuestionDivisionProps) => {
    const { winnersOnly } = useOrgData();

    return (
        <div className={`question-division size-${Object.entries(props.json).length}`}>
            {
                Object.entries(props.json.data).map(([division, players]) => {
                    // const playerData = winnersOnly ? players.slice(0, 1) : players;

                    const winningPlayers = players.filter(x => props.json.winners.some(w => w.teamPlayersId === x.tp_id));
                    let playerData = players;
                    
                    if (winnersOnly) {
                        if (winningPlayers.length > 0) {
                            playerData = winningPlayers;
                        }
                        else {
                            playerData = players.slice(0, 1);
                        }
                    }
                    
                    return (
                        <Card key={`division-${division}`} className="display-only">
                            <Card.Header>
                                <h3>{TeamDivision[Number(division)].toString()}</h3>
                            </Card.Header>
                            <Card.Content>
                                <div className="nominees">
                                    {
                                        playerData.map((player, i) => {
                                            return (
                                                <NomineeDisplay 
                                                    key={`${i}`} 
                                                    nominee={player} 
                                                    questionId={props.json.questionId}
                                                    showTeamName={false} 
                                                    isWinner={props.json.winners.some(x => x.teamPlayersId === player["tp_id"])}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </Card.Content>
                        </Card>
                    );
                })
            }
        </div>
    )

}

export default QuestionDivision;
