import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useEffect } from "react";
import Organizations from "./Organizations";
import Home from "./Home";
import OrganizationView from "./OrganizationView";

const AdminMain = () => {
    const { user } = useAuth();
    const nav = useNavigate();

    useEffect(() => {
        if (!user?.siteAdmin) {
            // Redirect to the user logout screen
            nav("/logout");
        }
    }, [user])

    return user?.siteAdmin ? (
        <div className="admin-page">
            <Routes>
                <Route path="/" element={<Home />}/>
                <Route path="organizations" element={<Organizations />} />
                <Route path="organizations/:orgId" element={<OrganizationView />} />
            </Routes>
        </div>
    ) : (
        <Navigate to="/" />
    )
}

export default AdminMain;
