import {useState, useEffect} from "react";

import "../../styles/scss/pointscale.scss";

type PointScaleAnswerProps = Readonly<{
    question: AwardQuestion
    currentValue?: AwardPlayerResponse
    onDecisionMade: (value: number) => void
}>;

const PointScaleAnswer = function(props: PointScaleAnswerProps) {
    const [labels, setLabels] = useState<Array<number>>([])
    const [value, setValue] = useState<number>(Number(props.currentValue?.response) || 0);

    useEffect(() => {
        const labelData = [];
        for (let i = props.question.min; i <= props.question.max; i++) {
            labelData.push(i);
        }

        setLabels(labelData);
    }, []);

    function onPointSliderChange(e: HTMLInputElement) {
        const pointValue = Number(e.value);
        setValue(pointValue);
        props.onDecisionMade(pointValue);
    }

    return (
        <div className="point-scale">
            <input 
                id="scale"
                type="range" 
                min={props.question.min} 
                max={props.question.max} 
                value={value} 
                step={1}
                onChange={(e) => onPointSliderChange(e.currentTarget)}
                onMouseUp={(e) => onPointSliderChange(e.currentTarget)}
            />
            <div className="ticks">
                {
                    labels.map((label) => (
                        <span key={label} className="tick">{label}</span>
                    ))
                }
            </div>
        </div>
    )

}

export default PointScaleAnswer;