import { Link } from "react-router-dom";
import LeagueApps from "./integrations/LeagueApps";
import LeagueLab from "./integrations/LeagueLab";
import { ReactComponent as HomeLogo } from "../../svgs/home.svg";

import "../../styles/scss/admin-integrations.scss";

const Integrations = () => {

    return (
        <div id="admin-integrations">
            <div className="breadcrumbs">
                <h3>
                    <Link to="../">
                        <HomeLogo className="icon" />
                    </Link> - Integrations
                </h3>
            </div>
            <div className="integrations">
                <LeagueApps />
                <LeagueLab />
            </div>
        </div>
    )
}

export default Integrations;
