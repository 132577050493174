import React from 'react';
import { createRoot } from 'react-dom/client';

import App from "./components/App"
import reportWebVitals from './reportWebVitals';

createRoot(document.querySelector("#root")!)
  .render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

reportWebVitals();
