import { ChangeEvent, createRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CSVDownload } from "react-csv";
import QRCode from "react-qr-code";
import { useOrgData } from "../../context/OrgContext";
import { useAlert } from "../../context/AlertContext";
import { apiCall, apiRequest } from "../../functions";
import Loading from "../commmon/Loading";
import Button from "../commmon/Button";
import Modal from "../commmon/Modal";
import AlertTypeEnum from "../../enum/AlertTypeEnum";
import SeasonTeam from "./SeasonTeam";
import AwardResults from "./AwardResults";
import SeasonForm from "./forms/SeasonForm";
import PrintAwardsModal from "./modals/PrintAwardsModal";
import LeagueAppsModal from "./modals/LeagueAppsModal";
import LeagueAppsButton from "../buttons/LeagueAppsButton";
import LeagueLabButton from "../buttons/LeagueLabButton";
import LeagueLabModal from "./modals/LeagueLabModal";
// import SeasonReferees from "./SeasonReferees";
import SeasonCsvUploadForm from "./forms/SeasonCsvUploadForm";

import {ReactComponent as DownloadIcon} from "../../svgs/download3.svg";
import {ReactComponent as EditIcon} from "../../svgs/pencil.svg";
import {ReactComponent as RefreshIcon} from "../../svgs/refresh.svg";
import {ReactComponent as HomeIcon} from "../../svgs/home.svg";
import {ReactComponent as TrophyIcon} from "../../svgs/trophy.svg";
import {ReactComponent as PrintIcon} from "../../svgs/printer.svg";

import "../../styles/scss/admin-season.scss";
import "../../styles/scss/tab-group.scss";

enum SeasonTab {
    TEAMS,
    AWARDS,
    // REFEREES
}

const AwardSeason = function() {
    const nav = useNavigate();
    const qrCodeRef = createRef<QRCode & SVGSVGElement>();
    const { setAlertMessage, setAlertFromApiMessage } = useAlert();
    const { seasonId, orgId } = useParams();
    const { winnersOnly, season, teams, integrations, loadData, toggleWinnersOnly, reloadSeasonData } = useOrgData();
    const [addNewTeam, setAddNewTeam] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<number>(SeasonTab.TEAMS);
    const [csvData, setCSVData] = useState<string>();
    const [showLeagueApps, setShowLeagueApps] = useState<boolean>(false);
    const [showLeagueLab, setShowLeagueLab] = useState<boolean>(false);
    const [showCSVUpload, setShowCSVUpload] = useState<boolean>(false);
    const [showPrintAwardsModal, setShowPrintAwardsModal] = useState<boolean>(false);
    const [nonTeamPlayers, setNonTeamPlayers] = useState<TeamPlayers[]>([]);

    useEffect(() => {
        setIsLoading(true);
    }, [])
    
    useEffect(() => {
        loadData(orgId, seasonId)
        .finally(() => {
            setIsLoading(false);

            if (seasonId === "new") {
                setIsEditing(true);
            }
        });

        loadNonTeamPlayers();
    }, [seasonId, orgId]);

    function loadNonTeamPlayers() {
        apiCall<TeamPlayers[]>(`/teamplayers/freeagents/?seasonId=${seasonId}`)
        .then((resp) => setNonTeamPlayers(resp.data ?? []));
    }

    function onUploadIconClicked() {
        setShowCSVUpload(true);
    }

    function onUploadFileInputChanged(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.files && season && season.id) {
            const formData: FormData = new FormData();
            formData.append("file", e.target.files[0]);
            formData.append("seasonId", season.id.toString());

            setIsLoading(true);
            apiRequest<null>(`/upload/season`, {
                method: "POST",
                body: formData
            })
            .then((json) => {
                setAlertFromApiMessage(json);

                if (json.success) {
                    loadData(orgId, seasonId);
                }
            })
            .finally(() => {
                setIsLoading(false);
                e.target.value = "";
            })
        }
    }

    function onDownloadIconClicked() {
        fetch(`/awards/download/${season?.id}`)
        .then(body => body.text())
        .then((data) => {
            if (data) {
                setCSVData(data)
                setTimeout(() => {
                    setCSVData(undefined);
                }, 5000)
            }
            else {
                console.error("")
            }
        });
    }

    function onRefreshIconClicked() {
        setIsRefreshing(true);
        loadNonTeamPlayers();
        reloadSeasonData()
        .then((success) => {
            setIsRefreshing(false);

            if (success) {
                setAlertMessage("Season information was refreshed", AlertTypeEnum.SUCCESS);
            }
            else {
                setAlertMessage("An error occured when refreshing the season", AlertTypeEnum.ERROR);
            }
        })
        .catch(() => {
            setAlertMessage("An error occured when refreshing the season", AlertTypeEnum.ERROR);
        })
        .finally(() => {
            setIsRefreshing(false)
        })
    }

    function onNewTeamClick() {
        setAddNewTeam(true);
    }

    function onFormCancel() {
        if (season) {
            setIsEditing(false);
        }
        else {
            nav(`../seasons`);
        }
    }

    function onFormSuccess(season: Season | null) {
        if (season) {
            if (seasonId === "new") {
                // Redirect to the new season
                nav(`../seasons/${season.id}`, { replace: true });
            }

            loadData(orgId, seasonId);
        }
        else if (season === null) {
            nav(`../seasons/`);
        }

        setIsEditing(false);
    }

    function onSeasonCsvSuccess() {
        onRefreshIconClicked();
        setShowCSVUpload(false)
    }

    function onCloseIntegrationModal() {
        setShowLeagueApps(false)
        setShowLeagueLab(false);
        setIsEditing(false);
        onRefreshIconClicked();
    }

    function onQRCodeDownloadClicked() {
        const svg = qrCodeRef.current;
        const link = document.createElement("a");

        if (svg && link) {
            const svgData = (new XMLSerializer()).serializeToString(svg);
            const url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgData);
            
            link.setAttribute("href", url);
            link.setAttribute("download", "VoxVerdict_QRCode.svg");
            link.click();
        }
    }

    
    function onPrintAwardsClick() {
        setShowPrintAwardsModal(true);
    }

    function onClosePrintAwardsModal(pdfData?: Blob) {
        setShowPrintAwardsModal(false);

        // if (pdfData) {
        //     setCSVData(pdfData);
        //     setTimeout(() => {
        //         setCSVData(undefined);
        //     }, 5000)
        // }
    }
    
    return (
        <div className="admin-season">
            {
                isEditing ? (
                    <div>
                        <SeasonForm 
                            onFormCancel={onFormCancel}
                            onFormSuccess={onFormSuccess}
                            season={season}
                        />
                    </div>
                ) : (
                    <>
                        <h3 className="season-header flex sticky">
                            <div className="season-name">
                                {season?.name}<br/>
                                {season?.seasonType.name} {season?.sport?.name} {season?.year}
                            </div>
                            <div className="season-actions flex">
                                <Link to="../seasons">
                                    <HomeIcon className="icon" />
                                </Link>
                                <EditIcon className="icon-edit icon" onClick={() => setIsEditing(true)} />
                                <RefreshIcon className={`icon-refresh icon ${isRefreshing ? "refreshing" : "refreshed"}`} onClick={onRefreshIconClicked} />
                                <DownloadIcon className="icon-download icon" onClick={onDownloadIconClicked} />
                                <TrophyIcon className={`icon-trophy icon ${winnersOnly ? `active` : `not-active`}`} onClick={toggleWinnersOnly} />
                                {/* <Link to="./certificates">
                                    <PrintIcon className={`icon-printer icon`} onClick={onPrintAwardsClick} />
                                </Link> */}
                                <PrintIcon className={`icon-printer icon`} onClick={onPrintAwardsClick} />
                            </div>
                        </h3>
                        <div className="tab-group">
                            {
                                Object.keys(SeasonTab).filter(k => isNaN(Number(k))).map((key, i) => {
                                    return (
                                        <Button key={key} className={`tab ${currentTab === i ? "selected" : ""}`} onClick={() => setCurrentTab(i)}>
                                            {key}
                                        </Button>
                                    )
                                })
                            }
                        </div>
                        {
                            currentTab === SeasonTab.AWARDS ? (
                                <AwardResults />
                            ) : null
                        }
                        {
                            currentTab === SeasonTab.TEAMS ? (
                                <div className="season-teams">
                                    {
                                        teams.map(team => {
                                            return (
                                                <SeasonTeam 
                                                    key={`team-${team.id}`} 
                                                    team={team} 
                                                />
                                            )
                                        })
                                    }
                                    {
                                        nonTeamPlayers.length > 0 ? (
                                            <SeasonTeam 
                                                key={"nonteamplayers"}
                                                team={{
                                                    name: "Free Agents",
                                                    seasonId: season?.id || Number(seasonId),
                                                    teamPlayers: nonTeamPlayers
                                                } as Team}
                                            />
                                        ) : null
                                    }
                                    {
                                        addNewTeam ? (
                                            <SeasonTeam 
                                                team={{ 
                                                    teamPlayers: [] as TeamPlayers[]
                                                } as Team}
                                                onHideNewTeamForm={() => setAddNewTeam(false)}
                                            />
                                        ) : (
                                            <Button className="card center" onClick={onNewTeamClick}>
                                                Add Team
                                            </Button>
                                        )
                                    }
                                </div>
                            ) : null
                        }
                        {
                            // currentTab === SeasonTab.REFEREES ? (
                            //     <SeasonReferees />
                            // ) : null
                        }
                        <div className="import-options">
                            <div className="buttons">
                                <div>
                                    <Button onClick={onUploadIconClicked}>Import CSV</Button>
                                </div>
                                <div>
                                    <Button onClick={onQRCodeDownloadClicked}>
                                        <div>
                                            Download QR Code
                                        </div>
                                        <div>
                                            <QRCode 
                                                value={`https://voxverdict.com`}
                                                size={64}
                                            />
                                            <QRCode 
                                                ref={qrCodeRef}
                                                className="hidden"
                                                size={512}
                                                value={`https://voxverdict.com`}
                                            />
                                        </div>
                                    </Button>
                                </div>
                                {
                                    integrations.find(x => x.name === "leagueapps") && (
                                        <LeagueAppsButton onClick={() => setShowLeagueApps(true)} />
                                    )
                                }
                                {
                                    integrations.find(x => x.name === "leaguelab") && (
                                        <LeagueLabButton onClick={() => setShowLeagueLab(true)} />
                                    )
                                }
                            </div>
                        </div>
                    </>
                ) 
            }
            {
                isLoading ? (
                    <Loading />
                ) : null
            }
            {
                csvData ? (
                    <CSVDownload data={csvData} target="_blank" />
                ) : null
            }
            {
                showLeagueApps ? (
                    <LeagueAppsModal onClose={onCloseIntegrationModal}/>
                ) : null
            }
            {
                showLeagueLab ? (
                    <LeagueLabModal onClose={onCloseIntegrationModal}/>
                ) : null
            }
            {
                showCSVUpload ? (
                    <Modal onModalClose={() => setShowCSVUpload(false)}>
                        <SeasonCsvUploadForm 
                            season={season} 
                            onFormCancel={() => setShowCSVUpload(false)} 
                            onFormSuccess={onSeasonCsvSuccess}
                        />
                    </Modal>
                ) : null
            }
            {
                showPrintAwardsModal ? (
                    <PrintAwardsModal 
                        seasonId={season?.id}
                        onClose={onClosePrintAwardsModal} 
                    />
                ) : null
            }
        </div>
    )
}

export default AwardSeason;
