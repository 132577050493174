import { FormEvent, useEffect, useState } from "react"
import Form, { IFormCallback } from "../../commmon/Form";
import FormRow from "../../commmon/FormRow";
import QuestionTypeEnum from "../../../enum/QuestionType";
import { useOrgData } from "../../../context/OrgContext";
import { apiRequest } from "../../../functions";
import { useAlert } from "../../../context/AlertContext";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";
import SelectField from "../../fields/SelectField";
import { SelfVote } from "./questionforms/SelfVote";
import TeamVote from "./questionforms/TeamVote";
import PointScaleQuestionForm from "./questionforms/PointScaleQuestionForm";
import BestTeamPlayerQuestionForm from "./questionforms/BestTeamPlayerQuestionForm";
import MultipleChoiceQuestionForm from "./questionforms/MultipleChoiceQuestionForm";
import { AudianceTypeEnum } from "../../../enum/AudianceTypeEnum";

type QuestionFormProps = Readonly<{
    section: AwardSection
    question: AwardQuestion
}>

const QuestionForm = (props: QuestionFormProps & IFormCallback) => {
    const { season, awardData } = useOrgData();
    const { setAlertFromApiMessage, setAlertMessage } = useAlert();
    const [questions, setQuestions] = useState<AwardQuestion[]>([]);
    const [questionType, setQuestionType] = useState<number>(props.question?.questionTypeId || 0);
    
    const [invalidFields, setInvalidFields] = useState<GenericObject>({});

    useEffect(() => {
        const filteredQuestions: AwardQuestion[] = [];
        awardData?.awards.forEach((s) => {
            s.questions.forEach(q => {
                if (q.questionTypeId === QuestionTypeEnum.TEAMPLAYERS) {
                    filteredQuestions.push(q);
                }
            });
        })

        setQuestions(filteredQuestions);
    }, [awardData, setQuestions])

    async function onSubmit(e: FormEvent<HTMLFormElement>, formData: GenericObject): Promise<boolean> {
        if (formData["multipleChoiceAnswers"]) {
            formData["multipleChoiceAnswers"] = formData["multipleChoiceAnswers"].map((value: any) => {
                return {
                    questionId: props.question.id,
                    value
                }
            })
        }

        try {
            const result = await apiRequest<AwardQuestion>(`/awards/questions/save`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    question: {
                        ...props.question,
                        sectionId: props.section.id,
                        seasonId: season?.id,
                        text: formData["questionText"],
                        description: formData["questionDescription"],
                        audienceTypeId: formData["audienceTypeId"],
                        questionTypeId: formData["questionTypeId"],
                        min: formData["questionMin"],
                        max: formData["questionMax"],
                        fromQuestion: formData["fromQuestion"],
                        allowSelfVote: formData["questionSelfVote"],
                        allowSelfTeamVote: formData["questionSelfTeamVote"],
                        multipleChoiceAnswers: formData["multipleChoiceAnswers"]
                    }
                })
            })

            setAlertFromApiMessage(result);

            if (result.success) {
                props.onFormSuccess();
            }
            else {
                setInvalidFields(result.fields || {});
                if (props.onFormError) {
                    props.onFormError();
                }
            }
        }
        catch (err) {
            console.error(err);
            setAlertMessage(`There was an unexpcted error when saving the question.`, AlertTypeEnum.ERROR);
            if (props.onFormError) {
                props.onFormError();
            }
            return false;
        }
        finally {
            return true;
        }
    }

    async function onDelete(): Promise<boolean> {
        if (window.confirm("Are you sure you wish to delete this question? This is irreversible")) {
            try {
                const result = await apiRequest(`/awards/questions/delete`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        question: props.question
                    })
                });

                setAlertFromApiMessage(result);
                props.onFormSuccess();
            }
            catch (err) {
                console.error(err);
                setAlertMessage(`There was an unexpcted error when deleting the question.`, AlertTypeEnum.ERROR);
                if (props.onFormError) {
                    props.onFormError();
                }
                return false;
            }
            finally {
                return true;
            }
        }
        else {
            return true;
        }
    }

    return (
        <Form 
            onSubmitClicked={onSubmit}
            onDeleteClicked={onDelete}
            onCancelClicked={props.onFormCancel}
            showDelete={props.question?.id !== undefined}
        >
            <FormRow className={`full ${invalidFields?.text?.isValid === false ? "invalid" : "valid"}`}>
                <label>Question Title</label>
                <input name="questionText" type="text" defaultValue={props.question.text} />
            </FormRow>
            <FormRow className="full">
                <label>Description</label>
                <textarea name="questionDescription" defaultValue={props.question.description} />
            </FormRow>
            <FormRow className="half">
                <SelectField label="Question Type" name="questionTypeId" value={props.question.questionTypeId} onChange={(e) => setQuestionType(Number(e.currentTarget.value))}>
                    <option value={QuestionTypeEnum.TEAMPLAYERS}>Team Players</option>
                    <option value={QuestionTypeEnum.DIVISION}>Divisional Players</option>
                    <option value={QuestionTypeEnum.ROOKIEONLY}>Rookie Players</option>
                    <option value={QuestionTypeEnum.PLAYER}>Player Search</option>
                    <option value={QuestionTypeEnum.BESTTEAMPLAYER}>Nominated Players</option>
                    <option value={QuestionTypeEnum.TEAMPHOTO}>Team Photo</option>
                    <option value={QuestionTypeEnum.TEAM}>Teams</option>
                    <option value={QuestionTypeEnum.MULTIPLECHOICE}>Multiple Choice</option>
                    <option value={QuestionTypeEnum.POINTSCALE}>Scale</option>
                    <option value={QuestionTypeEnum.TEXT}>Text Response</option>
                    <option value={QuestionTypeEnum.MESSAGE}>Message</option>
                </SelectField>
            </FormRow>
            <FormRow className="half">
                <SelectField label="Audiance" name="audienceTypeId" value={props.question.audienceTypeId}>
                    <option value={AudianceTypeEnum.ALL}>All</option>
                    <option value={AudianceTypeEnum.PLAYER}>Players</option>
                    <option value={AudianceTypeEnum.CAPTAIN}>Captians</option>
                    {/* <option value={AudianceTypeEnum.REFEREE}>Referees</option> */}
                </SelectField>
            </FormRow>
            { 
                (questionType === QuestionTypeEnum.POINTSCALE || questionType === QuestionTypeEnum.REFEREERATING) && (
                    <PointScaleQuestionForm min={props.question.min} max={props.question.max} /> 
                )
            }
            {
                questionType === QuestionTypeEnum.BESTTEAMPLAYER && (
                    <BestTeamPlayerQuestionForm questions={questions} fromQuestion={props.question.fromQuestion} />
                )
            }
            {
                questionType === QuestionTypeEnum.MULTIPLECHOICE && (
                    <MultipleChoiceQuestionForm answers={props.question.multipleChoiceAnswers}/>   
                )
            }
            {
                // Questions that allow for self voting
                [
                    QuestionTypeEnum.BESTTEAMPLAYER,
                    QuestionTypeEnum.DIVISION,
                    QuestionTypeEnum.PLAYER,
                    QuestionTypeEnum.ROOKIEONLY,
                    QuestionTypeEnum.TEAMPLAYERS,
                ].includes(questionType) && (
                    <FormRow className="half">
                        <SelfVote allowSelfVote={props.question.allowSelfVote} />
                    </FormRow>
                )
            }
            {
                // Questions that allow for voting for the players same team
                [
                    QuestionTypeEnum.BESTTEAMPLAYER,
                    QuestionTypeEnum.DIVISION,
                    QuestionTypeEnum.PLAYER,
                    QuestionTypeEnum.ROOKIEONLY,
                    QuestionTypeEnum.TEAMPLAYERS,
                    QuestionTypeEnum.TEAM,
                    QuestionTypeEnum.TEAMPHOTO
                ].includes(questionType) && (
                    <FormRow className="half">
                        <TeamVote allowTeamVote={props.question.allowSelfTeamVote} />
                    </FormRow>
                )
            }
        </Form>
    )
}

export default QuestionForm;