import { useAlert } from "../../context/AlertContext";
import AlertTypeEnum from "../../enum/AlertTypeEnum";
import { PermissionLevel } from "../../enum/PermissionLevelEnum";
import { apiCall } from "../../functions";
import Card from "./Card";
import Button from "./Button";
import SelectField from "../fields/SelectField";
import { useOrgData } from "../../context/OrgContext";
import { useAuth } from "../../context/AuthContext";
import { ChangeEvent } from "react";

type UserDetailsProps = Readonly<{
    user: UserInOrganization
    onUserUpdated: () => void
}>

const UserDetails = (props: UserDetailsProps) => {
    const { setAlertMessage } = useAlert();
    const { org, getUserPermissionLevel } = useOrgData();
    const { user } = useAuth();

    function onRemoveUserFromOrg() {
        if (window.confirm(`Are you sure you want to remove ${props.user.user.firstName} ${props.user.user.lastName} from this organization?`)) {
            apiCall(`/organization/delete/user`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    orgId: props.user.organizationId,
                    userId: props.user.userId
                })
            })
            .then((json) => {
                if (json) {
                    setAlertMessage(`The user was removed from the organization successfully`, AlertTypeEnum.SUCCESS);
                    props.onUserUpdated();
                }
                else {
                    setAlertMessage(`The user was not removed from the organization`, AlertTypeEnum.ERROR);
                }
            })
            .catch((err) => {
                setAlertMessage(err.message, AlertTypeEnum.ERROR);
                console.error(err);
            })
        }
    }

    function onPermissionsChanged(e: ChangeEvent<HTMLSelectElement>) {
        apiCall<boolean>(`/organization/${org?.id}/user/permission`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                orgId: props.user.organizationId,
                userId: props.user.userId,
                permissionLevel: e.currentTarget.value
            })
        })
        .then((json) => {
            if (json.data) {
                setAlertMessage(`Successfully changed permissions for ${props.user.user.firstName} ${props.user.user.lastName}`, AlertTypeEnum.SUCCESS)
                props.onUserUpdated();
            }
            else {
                setAlertMessage(`An error occured while changing permissions for ${props.user.user.firstName} ${props.user.user.lastName}`, AlertTypeEnum.ERROR)
            }
        })
        .catch((err) => {
            setAlertMessage(`An error occured while changing permissions for ${props.user.user.firstName} ${props.user.user.lastName}`, AlertTypeEnum.ERROR)
            console.error(err);
        })
    }

    function canModifyUserDetails() {
        return user?.siteAdmin || getUserPermissionLevel(user as User) === PermissionLevel.OWNER
    }

    function onResetPasswordClicked() {
        apiCall(`/organization/${org?.id}/user/passwordreset`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                orgId: org?.id,
                userId: props.user.userId
            })
        })
        .then((json) => {
            if (json.data) {
                setAlertMessage(`The user's password has been reset.`, AlertTypeEnum.SUCCESS);
                props.onUserUpdated();
            }
            else {
                setAlertMessage(`There was an issue trying to reset the password.`, AlertTypeEnum.WARNING);
            }
        })
        .catch((err) => {
            console.error(err);
            setAlertMessage(`An unexpected error occured when attempting to reset the password.`, AlertTypeEnum.ERROR);
        })
    }

    return (
        <div className="user-details">
            <Card className="display-only">
                <Card.Header>
                    <h3>{props.user.user.firstName} {props.user.user.lastName}</h3>
                </Card.Header>
                <Card.Content>
                    <ul>
                        <li className="email">
                            Email: {props.user.user.email}
                        </li>
                        <li className="permission">
                            {
                                canModifyUserDetails() ? (
                                    <SelectField 
                                        label="Permissions" 
                                        defaultValue={props.user.permissionLevel}
                                        onChange={onPermissionsChanged}
                                    >
                                        <option value={PermissionLevel.NONE}>None</option>
                                        <option value={PermissionLevel.VIEW}>View</option>
                                        <option value={PermissionLevel.EDIT}>Edit</option>
                                        <option value={PermissionLevel.OWNER}>Owner</option>
                                    </SelectField>
                                ) : (
                                    `Permissions ${PermissionLevel[props.user.permissionLevel].toString()}`
                                )
                            }
                        </li>
                        <li className="options full right">
                            {
                                canModifyUserDetails() ? (
                                    <>
                                        {
                                            !props.user.user.canReset ? (
                                                <Button onClick={onResetPasswordClicked} className="active">Reset Password</Button>
                                            ) : null
                                        }
                                        <Button className="danger" onClick={onRemoveUserFromOrg}>
                                            Delete
                                        </Button>
                                    </>
                                ) : null 
                            }
                        </li>
                    </ul>
                </Card.Content>
            </Card>
        </div>
    )
}

export default UserDetails;
