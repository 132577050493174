import { useAlert } from "../../../context/AlertContext";
import { useOrgData } from "../../../context/OrgContext";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";
import { apiCall } from "../../../functions";
import { ReactComponent as StarEmptyIcon } from "../../../svgs/star-empty.svg";
import { ReactComponent as StarFillIcon } from "../../../svgs/star-full.svg";

type NomineeDisplayProps = Readonly<{
    nominee: any
    questionId: number
    showTeamName?: boolean
    isWinner?: boolean
}>

const NomineeDisplay = (props: NomineeDisplayProps) => {
    const { season, reloadAwardData } = useOrgData();
    const { setAlertMessage } = useAlert();
    const showTeamName = props.showTeamName ?? true;
    const isWinner = props.isWinner ?? false;

    function onWinnerClicked() {
        let name = "";
        if (props.nominee["p_firstName"]) {
            name = `${props.nominee["p_firstName"]} ${props.nominee["p_lastName"]}`
        }
        else {
            name = props.nominee["t_name"];
        }
        
        if (!isWinner) {
            console.log(props.nominee);
            apiCall<AwardPlayerWinner>(`/award/winners/save`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    teamPlayersId: props.nominee["tp_id"],
                    questionId: props.questionId,
                    teamId: props.nominee["t_id"],
                    seasonId: season?.id
                })
            })
            .then((resp) => {
                setAlertMessage(`${name} was decided the winner`, AlertTypeEnum.SUCCESS);
                reloadAwardData();
            })
            .catch((e) => {
                console.error(e);
                setAlertMessage(`There was an error in saving the winner.`, AlertTypeEnum.ERROR);
            })
        }
        else {
            apiCall<AwardPlayerWinner>(`/award/winners/remove`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    teamPlayersId: props.nominee["tp_id"],
                    teamId: props.nominee["t_id"],
                    questionId: props.questionId,
                    seasonId: season?.id
                })
            })
            .then((resp) => {
                setAlertMessage(`${name} was dethroned`, AlertTypeEnum.SUCCESS);
                reloadAwardData();
            })
            .catch((e) => {
                console.error(e);
                setAlertMessage(`An error caused ${name} to not be dethrowned.`, AlertTypeEnum.ERROR);
            })
        }
    }

    return (
        <div className={`nominee ${props.isWinner ? "winner" : "not-winner"}`}>
            <aside>{props.nominee["rank"]})</aside>
            <div className="nominee-name">
                <div>
                    {
                        props.nominee["p_firstName"] ? (
                                `${props.nominee["p_firstName"]} ` +
                                (props.nominee["p_aka"] ? ` "${props.nominee["p_aka"]}" ` : "") +
                                ` ${props.nominee["p_lastName"]}`
                        ) : (
                            props.nominee["t_name"]
                        )
                    }
                    
                </div>
                {
                    showTeamName ? (
                        <sub>
                            {props.nominee["t_name"]}
                        </sub>
                    ) : null
                }
            </div>
            <div className="nominee-vote">
                {props.nominee.votes || 0}
            </div>
            <div className="trophy">
                {
                    isWinner ? (
                        <StarFillIcon className="icon" onClick={onWinnerClicked} />
                    ) : (
                        <StarEmptyIcon className="icon" onClick={onWinnerClicked} />
                    )
                }
            </div>
        </div>
    )
}

export default NomineeDisplay;