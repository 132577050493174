import { useState } from "react";
import FormRow from "../../../commmon/FormRow";
import { SelfVote } from "./SelfVote";
import TeamVote from "./TeamVote";

type PointScaleQuestionFormProps = Readonly<{
    min: number,
    max: number
}>

const PointScaleQuestionForm = (props: PointScaleQuestionFormProps) => {
    const [pointMin, setPointMin] = useState<number>(Number(props.min) || 0);

    return (
        <>
            <FormRow className="half">
                <label>Minimum</label>
                <input 
                    name="questionMin"
                    type="number" 
                    pattern="[0-9]*"
                    inputMode="numeric"
                    min={0} 
                    step={1}
                    value={pointMin}
                    onChange={(e) => setPointMin(Number(e.currentTarget.value))}
                />
            </FormRow>
            <FormRow className="half">
                <label>Maximum</label>
                <input 
                    name="questionMax"
                    type="number" 
                    pattern="[0-9]*"
                    inputMode="numeric"
                    min={pointMin} 
                    step={1}
                    defaultValue={props.max}
                />
            </FormRow>
        </>
    )
}

export default PointScaleQuestionForm;
