import { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Card from "./commmon/Card";

const UserDashboard = () => {
    const nav = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        if (!user) {
            nav("/login", { replace: true });
        }
    }, [user])

    return (
        <div>
            <Card onClick={() => nav("/season")}>
                <Card.Header>
                    <h3>Awards</h3>
                </Card.Header>
            </Card>
            <Card onClick={() => nav("/org")}>
                <Card.Header>
                    <h3>Organization Dashboard</h3>
                </Card.Header>
            </Card>
            <Card onClick={() => nav("/admin")}>
                <Card.Header>
                    <h3>Admin Dashboard</h3>
                </Card.Header>
            </Card>
        </div>
    )
}

export default UserDashboard;
