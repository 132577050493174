import { Link } from "react-router-dom";

const OrgNav = (props: ClickableElement) => {
    return (
        <>
            <Link className="link" to="/org" onClick={props.onElementClicked}>Organization Home</Link>
        </>
    )
}

export default OrgNav;
