import CheckboxField from "../../../fields/CheckboxField";

type TeamVoteProps = Readonly<{
    allowTeamVote?: boolean
}>

const TeamVote = (props: TeamVoteProps) => {
    return (
        <CheckboxField 
            type="switch" 
            name="questionSelfTeamVote" 
            label="Can players vote for their team?" 
            checked={props.allowTeamVote ?? false} 
        />
    )
}

export default TeamVote;
