import { FormEvent, useEffect, useState } from "react";
import { useOrgData } from "../../context/OrgContext";
import UserDetails from "../commmon/UserDetails";
import CreateUserForm from "../forms/CreateUserForm";
import Button from "../commmon/Button";
import FormRow from "../commmon/FormRow";
import SelectField from "../fields/SelectField";
import { PermissionLevel } from "../../enum/PermissionLevelEnum";
import { apiCall } from "../../functions";
import { useAuth } from "../../context/AuthContext";
import AlertTypeEnum from "../../enum/AlertTypeEnum";
import { useAlert } from "../../context/AlertContext";
import { useNavigate } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../svgs/home.svg";
import { Link } from "react-router-dom";

const UserManager = () => {
    const nav = useNavigate();
    const { user } = useAuth();
    const { setAlertMessage } = useAlert();
    const { org, reloadOrgData, getUserPermissionLevel } = useOrgData();
    const [showUserForm, setShowUserForm] = useState<boolean>(false);

    useEffect(() => {
        if (!user?.siteAdmin && getUserPermissionLevel(user) !== PermissionLevel.OWNER) {
            nav("../", { replace: true })
        }
    }, [])

    async function onSubmitForm(e: FormEvent<HTMLFormElement>, formData: GenericObject): Promise<boolean> {
        try {
            const result = await apiCall<boolean>(`/organization/${org?.id}/user/add`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    orgId: org?.id,
                    permissionLevel: formData.permissionLevel,
                    user: {
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        email: formData.email,
                        password: formData.password
                    }
                })
            });

            if (result.data) {
                setAlertMessage(`The user has been added to the organization`, AlertTypeEnum.SUCCESS);
                setShowUserForm(false);
                reloadOrgData();
                return true;
            }
            else {
                setAlertMessage(`There was an issue with saving the user to the organization`, AlertTypeEnum.ERROR);
                return false;
            }
        }
        catch(err) {
            setAlertMessage(`There was an issue with saving the user to the organization. ${err}`, AlertTypeEnum.ERROR);
            return false;
        }
    }

    return (
        <div id="user-manager">
            <h3>
                <Link to="../">
                    <HomeIcon className="icon" />
                </Link> 
                User Management
            </h3>
            {
                org?.users.map((users) => {
                    return (
                        <UserDetails 
                            key={users.id}
                            user={users} 
                            onUserUpdated={reloadOrgData}
                        />
                    )
                })
            }
            {
                showUserForm ? (
                    <CreateUserForm 
                        onSubmit={onSubmitForm}
                        onCancel={() => setShowUserForm(false)}
                    >
                        <FormRow className="full">
                            <SelectField label="Permission" name="permissionLevel" required={true}>
                                <option value=""></option>
                                {
                                    user?.siteAdmin ? (
                                        <>
                                            <option value={PermissionLevel.NONE}>None</option>
                                            <option value={PermissionLevel.VIEW}>View</option>
                                            <option value={PermissionLevel.EDIT}>Edit</option>
                                            <option value={PermissionLevel.OWNER}>Owner</option>
                                        </>
                                    ) : (
                                        <>
                                            <option value={PermissionLevel.NONE}>None</option>
                                            <option value={PermissionLevel.VIEW}>View</option>
                                            <option value={PermissionLevel.EDIT}>Edit</option>
                                        </>
                                    )
                                }
                            </SelectField>
                        </FormRow>
                    </CreateUserForm>
                ) : (
                    <Button className="full" onClick={() => setShowUserForm(true)}>
                        Create User
                    </Button>
                )
            }
        </div>
    )
}

export default UserManager;
