//@ts-ignore
import CanvasJSReact from "@canvasjs/react-charts";

type QuestionMultipleChoiceProps = Readonly<{
    json: AdminVoteData<
        Array<{
            m_value: string,
            votes: string
        }>
    >
}>

const QuestionMultipleChoice = (props: QuestionMultipleChoiceProps) => {
    const CanvasJS = CanvasJSReact.CanvasJS;
    const CanvasChart = CanvasJSReact.CanvasJSChart;
    const pieOptions = {
        animationEnabled: true,
        data: [{
            type: "pie",
            showInLegend: true,
            legendText: "{label}",
            toolTipContent: "{label}: \n<strong>{y}</strong>",
            indexLabel: "{y}",
            indexLabelPlacement: "inside",
            explodeOnClick: false,
            dataPoints: props.json.data.map((x) => ({ y: x.votes, label: x.m_value }))
        }]
    }

    return (
        <div className="nominees">
            {
                props.json.data.length > 4 ? (
                    props.json.data.map((data, i) => {
                        return (
                            <div className="nominee" key={`${i}`}>
                                <div className="left">{data.m_value}</div>
                                <div className="right">{data.votes}</div>
                            </div>
                        )
                    })
                ) : (
                    <CanvasChart options={pieOptions} />
                )
            }
        </div>
    )
}

export default QuestionMultipleChoice;