//@ts-ignore
import CanvasJSReact from "@canvasjs/react-charts";

import "../../../styles/scss/admin-question-point-scale.scss";

type PointScaleFeedback = Readonly<{
    response: number,
    votes: number,
}>

type QuestionPointScaleFeedbackProps = Readonly<{
    json: AdminVoteData<
        Array<PointScaleFeedback>
    >
}>;

const QuestionPointScaleFeedback = function(props: QuestionPointScaleFeedbackProps) {
    const CanvasChart = CanvasJSReact.CanvasJSChart;
    const CanvasJS = CanvasJSReact.CanvasJS;

    CanvasJS.addColorSet("pointBarGraphColors",  [
        "#61BAFB"
    ])

    const canvasChartOptions = {
        animationEnabled: true,
        colorSet: "pointBarGraphColors",
        axisX: {
            title: "Value",
            includeZero: true
        },
        axisY: {
            title: "Votes",
        },
        data: [{
            type: "bar",
            dataPoints: props.json.data.sort((a, b) => Number(a.response) - Number(b.response)).map((d) => {
                return { 
                    y: Number(d.votes), 
                    label: d.response 
                }
            })
        }]
    }

    return (
        <div className="point-scale-feedback">
            <CanvasChart 
                options={canvasChartOptions}
                containerProps={{ width: '100%', height: '100%' }}
            />
        </div>
    )

}

export default QuestionPointScaleFeedback;