import clsx from "clsx";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, CSSProperties } from "react";
import { apiCall, apiRequest } from "../functions";
import { useAuth } from "../context/AuthContext";
import { useAlert } from "../context/AlertContext";
import Loading from "./commmon/Loading";
import Button from "./commmon/Button";
import Collapsible from "./commmon/Collapsible";
import Card from "./commmon/Card";
import { AnswerStatus } from "../enum/AnswerStatus";
import AlertTypeEnum from "../enum/AlertTypeEnum";
import Answers from "./Answers";

import "../styles/scss/vote.scss";

const Vote = function() {
    const nav = useNavigate();
    const { setAlertMessage } = useAlert();
    const { player } = useAuth();
    const state = useLocation().state as TeamPlayers;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [disableNav, setDisableNav] = useState<boolean>(false);
    const [currentQuestion, setCurrentQuestion] = useState<number>(-1);
    const [answerStatuses, setAnswerStatuses] = useState<QuestionAnswerStatus[]>([]);
    const [voterResponses, setVoterResponses] = useState<AwardPlayerResponse[]>([]);
    const [questions, setQuestions] = useState<AwardQuestion[]>([]);
    const [avaiableAnswers, setAvailableAnswers] = useState<Array<{ questionId: number, data: Team[] | TeamPlayers[] | MultipleChoiceAnswer[] }>>([]);
    const [newDecision, setNewDecision] = useState<AwardPlayerResponse>();

    useEffect(() => {
        if (!player) {
            console.debug("Player was not logged in");
            nav("/login", { replace: true });
        }
        else if (isNaN(Number(state.seasonId))) {
            console.debug("Season was not properly selected");
            nav("/season", { replace: true });
        }
        else {
            setIsLoading(true);
            apiRequest<{
                questions: AwardQuestion[], 
                answers: Array<{ questionId: number, data: TeamPlayers[] | Team[] | MultipleChoiceAnswer[]}>,
                responses: AwardPlayerResponse[],
                statuses: QuestionAnswerStatus[]
            }>(`/awards/data/?tpId=${state.id}`)
            .then((json) => {
                if (json.data) {
                    setQuestions(json.data.questions)
                    setAvailableAnswers(json.data.answers)
                    setVoterResponses(json.data.responses);
                    setAnswerStatuses(json.data.statuses);
                }
                setCurrentQuestion(0);
            })
            .finally(() => {
                setIsLoading(false);
            })
        }
    }, [state]);

    async function setQuestionNumber(questionNumber: number) {
        if (!disableNav) {
            // Prepare to send the user's response!
            setIsLoading(true);
            const savedAnswer = await saveNewDecision();

            if (savedAnswer) {
                let qNum = questionNumber;
                if (questionNumber >= questions.length) {
                    qNum = questions.length - 1;
                }
                else if (questionNumber < 0) {
                    qNum = 0;
                }

                window.scrollTo({ top: 0, behavior: "smooth" });
                setCurrentQuestion(qNum);
            }

            setIsLoading(false);
        }
    }

    function onQuestionNavClick(qNum: number) {
        setQuestionNumber(qNum);
    }

    function onPrevQuestionClick() {
        setQuestionNumber(currentQuestion - 1);
    }

    function onNextQuestionClick() {
        setQuestionNumber(currentQuestion + 1);
    }

    function onDecisionMadeForQuestion(resp: AwardPlayerResponse) {
        const newResponses = [
            ...voterResponses.filter(x => x.questionId !== resp.questionId),
            resp
        ];
        
        setNewDecision(resp);
        setVoterResponses(newResponses);
    }

    function onAnswerHasError(hasError: boolean, message: string) {
        setDisableNav(hasError);
    }

    async function saveNewDecision(): Promise<boolean> {
        if (newDecision) {
            try {
                const resp = await apiCall<AwardPlayerResponse>(`/awards/vote`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        teamPlayerId: state.id,
                        seasonId: state.seasonId,
                        teamId: state.teamId,
                        playerId: state.playerId,
                        response: newDecision
                    })
                })

                setNewDecision(undefined);
                setAlertMessage("Your response has been recorded.", AlertTypeEnum.SUCCESS);
                updateQuestionStatus(resp.data.questionId, AnswerStatus.SUCCESS);
                return true;
            }
            catch(err) {
                setAlertMessage((err as Error).message, AlertTypeEnum.ERROR);
                updateQuestionStatus(newDecision.questionId, AnswerStatus.FAIL);
                return false
            }
        }
        else {
            return true;
        }
    }

    function updateQuestionStatus(questionId: number, status: AnswerStatus) {
        const responses = [...answerStatuses];
        const questionStatus = responses.find(x => x.questionId === questionId);
        
        if (questionStatus) {
            questionStatus.status = status
        }
        else {
            console.warn(`Question is not part of the status array.`)
        }

        setAnswerStatuses(responses);
    }

    return questions.length === 0 ? (
        <div className="center">
            <p>Humm... there doesn't seem to be any prompts at this time.</p>
            <p>Please check again later.</p>
        </div>
    ) : (
        <div id="vote">
            <div id="question-wrapper">
                <nav id="question-nav">
                    <Collapsible title="Question Navigation">
                        {
                            questions.map((question, i) => {
                                const answerStatus = answerStatuses.find(x => x.questionId === question.id) as QuestionAnswerStatus;
                                const questionMapClasses = clsx(
                                    AnswerStatus[answerStatus?.status]?.toString().toLowerCase() ?? AnswerStatus.NONE,
                                    "question-button",
                                    i === currentQuestion ? "selected" : "",
                                    (disableNav || (question.section?.locked ?? false)) ? "disabled" : "",
                                )
                                return (
                                    <Button
                                        key={question.id}
                                        className={questionMapClasses}
                                        onClick={() => onQuestionNavClick(i)}
                                    >
                                        { question.text }
                                    </Button>
                                )
                            })
                        }
                        <aside className="legend">
                            Legend:
                            <div className="legend-cards">
                                <Card className={`display-only ${AnswerStatus[AnswerStatus.SUCCESS].toString().toLowerCase()}`}>Submitted</Card>
                                <Card className={`display-only ${AnswerStatus[AnswerStatus.FAIL].toString().toLowerCase()}`}>Failed</Card>
                                <Card className={`display-only ${AnswerStatus[AnswerStatus.NONE].toString().toLowerCase()}`}>No Vote</Card>
                                <Card className={`display-only disabled`}>Locked</Card>
                                <Card className={`display-only selected`}>Viewing</Card>
                            </div>
                        </aside>
                    </Collapsible>
                </nav>
                <div id="award-question-wrapper">
                    <div className="award-question" style={{"--viewing": currentQuestion} as CSSProperties}>
                    {
                        questions.map((q, i) => (
                            <section key={`questionid-${q.id}`} className={`question ${i === currentQuestion ? "active" : "inactive"}`}>
                                <div className="header">
                                    <h3> {q.text} </h3>
                                </div>
                                <div className="description">
                                    {
                                        q.description ? (
                                            <p>
                                                {q.description}
                                            </p>
                                        ) : null
                                    }
                                </div>
                                <Answers 
                                    question={q}
                                    currentAnswer={voterResponses.find(x => x.questionId === q.id)}
                                    answers={avaiableAnswers.find(x => x.questionId === q.id)?.data || []}
                                    onDecisionMadeForQuestion={onDecisionMadeForQuestion}
                                    onAnswerHasError={onAnswerHasError}
                                />
                            </section>
                        ))
                    }
                    </div>
                </div>
            </div>
            <div className="nav-buttons">
                <Button
                    disabled={disableNav}
                    className="nav-prev-button selected" 
                    onClick={onPrevQuestionClick}
                >
                    Previous
                </Button>
                <Button
                    disabled={disableNav}
                    className="nav-next-button selected" 
                    onClick={onNextQuestionClick}
                >
                    Next
                </Button>
            </div>
            {
                isLoading ? (
                    <Loading />
                ) : null
            }
        </div>
    )

}

export default Vote;