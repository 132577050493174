import React, { createRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { apiCall } from "../../functions";
import { useOrgData } from "../../context/OrgContext";

import { ReactComponent as HomeIcon } from "../../svgs/home.svg";
import "../../styles/scss/award-certificate.scss";

const AwardCertificate = () => {
    const { seasonId, orgId } = useParams();
    const { org, season } = useOrgData();
    const [winnerCerts, setWinnerCerts] = useState<string[]>([])
    const certContainerRef = createRef<HTMLDivElement>();

    useEffect(() => {
        apiCall<string[]>(`/awards/print/?seasonId=${season?.id || seasonId}`)
        .then((res) => {
            setWinnerCerts(res.data)
        });
    }, []);

    return (
        <div className="award-certificates">
            <header>
                <Link to={`../seasons/${season?.id || seasonId}`}>
                    <HomeIcon className="icon" />
                </Link>
            </header>
            {
                winnerCerts.map((x, i) => {
                    return (
                        <div key={`cert-${i}`} className="certificate" dangerouslySetInnerHTML={{ __html: x }} />
                    )
                })
            }
        </div>
    )
}

export default AwardCertificate;
