import FormRow from "../../commmon/FormRow";
import Form, { IFormCallback } from "../../commmon/Form";
import { FormEvent } from "react";
import CheckboxField from "../../fields/CheckboxField";
import InputField from "../../fields/InputField";
import { useOrgData } from "../../../context/OrgContext";
import { apiRequest } from "../../../functions";
import { useAlert } from "../../../context/AlertContext";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";

type TeamPlayerFormProps = Readonly<{
    teamPlayer?: TeamPlayers
}>

const TeamPlayerForm = (props: TeamPlayerFormProps & IFormCallback) => {
    const { season } = useOrgData();
    const { setAlertMessage } = useAlert();

    async function onFormSubmit(e: FormEvent<HTMLFormElement>, formData: GenericObject): Promise<boolean> {
        const bodyData = {
            id: props.teamPlayer?.id,
            seasonId: season?.id,
            teamId: props.teamPlayer?.teamId,
            captian: formData["captian"],
            playerId: props.teamPlayer?.playerId,
            player: {
                id: props.teamPlayer?.playerId,
                firstName: formData["firstName"],
                lastName: formData["lastName"],
                email: formData["email"],
                aka: formData["aka"]
            }
        } as Partial<TeamPlayers>;
        
        try {
            const result = await apiRequest<TeamPlayers>(`/teamplayers/save`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({teamPlayer: bodyData})
            })

            if (result.success && result.data) {
                setAlertMessage(result.message, AlertTypeEnum.SUCCESS);
                props.onFormSuccess();
            }
            else {
                console.error(result);
                setAlertMessage(result.message, AlertTypeEnum.ERROR);
            }
        }
        catch (err) {
            console.error(err);
            setAlertMessage(`There was an issue saving the player.`, AlertTypeEnum.ERROR);
            if (props.onFormError) {
                props.onFormError();
            }
            return false;
        }
        finally {
            return true;
        }
    }

    async function onFormDelete(): Promise<boolean> {
        try {
            if (window.confirm(`Are you sure you wish to delete ${props.teamPlayer?.player.firstName} ${props.teamPlayer?.player.lastName} from the team?`)) {
                const result = await apiRequest(`/teamplayers/remove`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        id: props.teamPlayer?.id,
                        playerId: props.teamPlayer?.playerId,
                        seasonId: season?.id,
                        teamId: props.teamPlayer?.teamId
                    })
                });

                if (result.success) {
                    setAlertMessage(result.message, AlertTypeEnum.SUCCESS);
                    props.onFormSuccess();
                }
                else {
                    console.error(result);
                    setAlertMessage(result.message, AlertTypeEnum.ERROR);
                }
            }
        }
        catch(err) {
            console.error(err);
            setAlertMessage(`There was an issue removing the player.`, AlertTypeEnum.ERROR);
            if (props.onFormError) {
                props.onFormError();
            }
            return false;
        }
        finally {
            return true;
        }
    }

    return (
        <Form
            onSubmitClicked={onFormSubmit}
            onDeleteClicked={onFormDelete}
            onCancelClicked={props.onFormCancel}
            showDelete={props.teamPlayer?.id !== undefined}
        >
            <FormRow className="full">
                <InputField 
                    type="email"
                    name="email"
                    placeholder="Email"
                    defaultValue={props.teamPlayer?.player?.email || ""}
                />
            </FormRow>
            <FormRow>
                <InputField 
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    defaultValue={props.teamPlayer?.player?.firstName || ""}
                />
            </FormRow>
            <FormRow>
                <InputField 
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    defaultValue={props.teamPlayer?.player?.lastName || ""}
                />
            </FormRow>
            <FormRow>
                <InputField 
                    type="text"
                    name="aka"
                    placeholder="AKA"
                    defaultValue={props.teamPlayer?.player?.aka || ""}
                />
            </FormRow>
            <FormRow>
                <CheckboxField
                    label="Captian"
                    type="switch"
                    checked={props.teamPlayer?.captian || false}
                    name="captian"
                />
            </FormRow>
        </Form>
    )
}

export default TeamPlayerForm;