import { FormEvent, useState } from "react";
import Form from "../../commmon/Form"
import FormRow from "../../commmon/FormRow";
import FileUploadField from "../../fields/FileUploadField";
import { apiCall } from "../../../functions";
import { useAlert } from "../../../context/AlertContext";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";
import CheckboxField from "../../fields/CheckboxField";

type SeasonCsvUploadFormProps = Readonly<{
    season?: Season
    onFormSuccess: () => void
    onFormCancel: () => void
}>

const SeasonCsvUploadForm = (props: SeasonCsvUploadFormProps) => {
    const { setAlertMessage } = useAlert();
    const [acknowledge, setAcknowledgement] = useState<boolean>(false);

    async function onFormSubmit(e: FormEvent<HTMLFormElement>, formData: GenericObject): Promise<boolean> {
        const form: FormData = new FormData();
        form.append("file", formData["csv"]);
        form.append("seasonId", props.season?.id.toString() || "");

        try {
            const result = await apiCall<boolean>(`/upload/season`, {
                method: "POST",
                body: form
            })

            if (result.data) {
                setAlertMessage(`The CSV was successfully processed`, AlertTypeEnum.SUCCESS);
                props.onFormSuccess();
            }
            else {
                console.warn(`Error in uploading CSV`);
                setAlertMessage(`There was an issue in uploading your CSV.`, AlertTypeEnum.ERROR);
            }
        }
        catch (err) {
            console.error(err);
            setAlertMessage((err as Error).message, AlertTypeEnum.ERROR);
        }

        return false;
    }

    return (
        <div>
            <h2>Season Csv Upload</h2>
            <p>The CSV needs to include these as row 1: </p>
            <p>First Name, Last Name, Email, Captian, Team Name</p>
            <Form 
                onSubmitClicked={onFormSubmit}
                onCancelClicked={props.onFormCancel}
                enableSubmissions={acknowledge}
            >
                
                <FormRow className="full">
                    <FileUploadField label="CSV File" name="csv" accept=".csv" />
                </FormRow>
                <FormRow className="full">
                    <CheckboxField 
                        name="acknowledgement" 
                        label="You understand, this operation will destroy all existing player responses, teams, and players." 
                        type="checkbox" 
                        checked={acknowledge}
                        onClick={(e) => setAcknowledgement(e)}
                    />
                </FormRow>
            </Form>
        </div>
    )
}

export default SeasonCsvUploadForm;
