import QuestionTypeEnum from "../../enum/QuestionType";
import QuestionLeagueVotes from "./awardvotes/QuestionLeagueVotes";
import QuestionTeamVotes from "./awardvotes/QuestionTeamVotes";
import QuestionTextFeedback from "./awardvotes/QuestionTextFeedback";
import QuestionPointScaleFeedback from "./awardvotes/QuestionPointScaleFeedback";
import QuestionTeamPhoto from "./awardvotes/QuestionTeamPhoto";
import QuestionMultipleChoice from "./awardvotes/QuestionMultipleChoice";
import QuestionRefereeRating from "./awardvotes/QuestionRefereeRating";
import QuestionDivision from "./awardvotes/QuestionDivision";

import "../../styles/scss/admin-question-votes.scss";

type QuestionVotesProps = Readonly<{
    question: AwardQuestion
    voteData: AdminVoteData<any>
}>;

const QuestionVotes = function(props: QuestionVotesProps) {
    function getDisplayType(): JSX.Element {
        switch(props.question.questionTypeId) {
            case QuestionTypeEnum.TEAMPLAYERS:
                return (<QuestionTeamVotes json={props.voteData} />);
            case QuestionTypeEnum.PLAYER:
            case QuestionTypeEnum.ROOKIEONLY:
            case QuestionTypeEnum.BESTTEAMPLAYER:
                return (<QuestionLeagueVotes json={props.voteData} />);
            case QuestionTypeEnum.DIVISION:
                return (<QuestionDivision json={props.voteData} />);
            case QuestionTypeEnum.TEXT:
                return (<QuestionTextFeedback json={props.voteData} />);
            case QuestionTypeEnum.POINTSCALE:
                return (<QuestionPointScaleFeedback json={props.voteData} />);
            case QuestionTypeEnum.TEAMPHOTO:
            case QuestionTypeEnum.TEAM:
                return (<QuestionTeamPhoto json={props.voteData} />);
            case QuestionTypeEnum.MULTIPLECHOICE:
                return (<QuestionMultipleChoice json={props.voteData} />);
            case QuestionTypeEnum.REFEREERATING:
                return (<QuestionRefereeRating json={props.voteData} />);
            default: 
                return (
                    <></>
                )
        }
    }

    return (
        <div className="question-votes">
            { getDisplayType() }
        </div>
    )
    
}

export default QuestionVotes;
