import {useState, ChangeEvent} from "react";
import { useLocation } from "react-router-dom";
import { apiRequest } from "../../functions";
import useDebounce from "../../hooks/useDebounce";
import SelectableAnswer from "./SelectableAnswer";
import InputField from "../fields/InputField";

type PlayerSearchAnswerProps = Readonly<{
    currentValue?: AwardPlayerResponse
    disablePlayerId?: number
    disableTeamId?: number
    onDecisionMade: (id: number) => void
}>;

const PlayerSearchAnswer = function(props: PlayerSearchAnswerProps) {
    const state = useLocation().state as TeamPlayers;
    const [playerSearch, setPlayerSearch] = useState<string>("");
    const [teamPlayers, setTeamPlayers] = useState<TeamPlayers[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const debounce = useDebounce(onPlayerSearch, 1000);

    function onPlayerSearch(searchText: string = "") {
        apiRequest<TeamPlayers[]>(`/teamplayers/search?sId=${state.seasonId}&value=${searchText}`)
        .then((json) => {
            setTeamPlayers(json.data || [])
            setIsLoading(false);
        });
    }

    function onPlayerSearchChange(e: ChangeEvent<HTMLInputElement>) {
        setPlayerSearch(e.currentTarget.value);

        if (e.currentTarget.value) {
            debounce(e.currentTarget.value)
            setIsLoading(true);
        }
        else {
            setTeamPlayers([]);
        }
    }

    return (
        <div className="player-search-answer">
            
            <div className="search-bar">
                {
                    props.currentValue?.responseValue ? (
                        <div className="current-vote">
                            <div className="label">
                                Current Vote
                            </div>
                            <SelectableAnswer
                                answers={[props.currentValue.responseValue]}
                            />
                        </div>
                    ) : null
                }
                <div className="search-box">
                    <InputField
                        name="player-search"
                        type="text"
                        onChange={onPlayerSearchChange}
                        value={playerSearch}
                    />
                </div>
            </div>
            <div className="center">
                {
                    teamPlayers.length > 0 ? (
                        <SelectableAnswer
                            currentValue={props.currentValue}
                            answers={teamPlayers}
                            onDecisionMade={props.onDecisionMade}
                            disablePlayerId={props.disablePlayerId}
                            disableTeamId={props.disableTeamId}
                            showFilter={false}
                        />
                    ) : (
                        isLoading ? (
                            "Searching..."
                        ) : (
                            "Use the search bar to search for players"
                        )
                    )
                }
            </div>
        </div>
    )

}

export default PlayerSearchAnswer;