import { useEffect, useState } from "react";
import { ScreenSizeEnum } from "../enum/ScreenSizeEnum";

function useScreenSize() {
    const [screenSize, setScreenSize] = useState<ScreenSizeEnum>();

    useEffect(() => {
        window.addEventListener("resize", onScreenResize);
        onScreenResize();

        return () => {
            window.removeEventListener("resize", onScreenResize);
        }
    }, [])

    function onScreenResize() {
        const bodyElement = document.querySelector("body")?.getBoundingClientRect();
        
        if (bodyElement) {
            if (bodyElement.width < 768) {
                setScreenSize(() => ScreenSizeEnum.MOBILE);
            }
            else if (bodyElement.width >= 768 && bodyElement.width < 1024) {
                setScreenSize(() => ScreenSizeEnum.MEDIUM);
            }
            else {
                setScreenSize(() => ScreenSizeEnum.LARGE);
            }
        }
        else {
            console.warn("Unable to get screen size");
        }
    }
    
    return {
        screenSize
    }
}

export default useScreenSize;
