import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { apiCall, apiRequest } from "../functions";

type LoginResult = {
    success: boolean, 
    showPassword: boolean, 
    isUser: boolean, 
    isPlayer: boolean
}

type AuthProviderProps = {
    children: ReactNode
}

type AuthContextType = {
    user?: User
    player?: Player
    login: (email: string, password?: string) => Promise<LoginResult>
    logout: () => Promise<void>
}

const AuthContext = createContext({} as AuthContextType);

export function AuthProvider({ children }: AuthProviderProps) {
    const [player, setPlayer] = useState<Player | undefined>();
    const [user, setUser] = useState<User | undefined>();

    useEffect(() => {
        // Check to see if the user is already logged in
        apiCall<User>(`/user/current`)
        .then((json) => {
            if (json.data) {
                setUser(json.data);
                playerLogin(json.data.email);
            }
        })
        .catch((err) => {
            console.error(err);
        })
    }, []);

    async function login(email: string, password?: string): Promise<LoginResult> {
        const resp: LoginResult = { 
            success: false, 
            showPassword: false, 
            isUser: false, 
            isPlayer: false 
        };

        if (email && password) {
            const userLoginResult = await adminLogin(email, password);
            resp.isUser = true;
            resp.success = userLoginResult;

            if (userLoginResult) {
                // Attempt a player save as well
                await playerLogin(email);
            }
        }
        else if (email && !password) {
            const hasAccount = await hasUserAccount(email);
            if (hasAccount) {
                resp.isUser = true;
                resp.showPassword = true;
            }
            else {
                const hasPlayerAccount = await playerLogin(email);
                if (hasPlayerAccount) {
                    resp.isPlayer = true;
                    resp.success = true;
                }
            }
        }
        
        return resp;
    }

    async function hasUserAccount(email: string): Promise<boolean> {
        // Attempt to see if the user is logging in as an admin first.
        const userAccountCheck = await apiCall<boolean>(`/user/check`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email
            })
        });

        return userAccountCheck.data;
    }

    async function adminLogin(email: string, password: string): Promise<boolean> {
        const loginResult = await apiCall<User>(`/user/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email,
                password
            })
        })
        
        if (loginResult.resp.ok) {
            setUser(loginResult.data);
            return true;
        }
        else {
            return false;
        }
    }

    async function playerLogin(email: string): Promise<boolean> {
        const playerLogin = await apiRequest<Player>(`/players/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email
            })
        });

        if (playerLogin.success) {
            setPlayer(playerLogin.data);
            return true;
        }
        else {
            return false;
        }
    }

    async function logout(): Promise<void> {
        await apiCall<User>(`/user/logout`)
        await apiCall<User>(`/players/logout`)
        setUser(undefined);
        setPlayer(undefined);
    }

    return (
        <AuthContext.Provider value={{
            player,
            user,
            login,
            logout
        }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
