import { useState } from "react";
import Card from "../../commmon/Card";
import Form from "../../commmon/Form";
import FormRow from "../../commmon/FormRow";
import InputField from "../../fields/InputField";
import CheckboxField from "../../fields/CheckboxField";

import { ReactComponent as EditIcon } from "../../../svgs/pencil.svg";
import { apiCall } from "../../../functions";
import { useOrgData } from "../../../context/OrgContext";

import "../../../styles/scss/forms/SportForm.scss";
import { useAlert } from "../../../context/AlertContext";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";

type SportFormProps = Readonly<{
    sport?: Sport
    isEditing?: boolean
    onFormSubmitted?: () => void
}>

const SportForm = (props: SportFormProps) => {
    const { setAlertMessage } = useAlert();
    const { org, reloadSportData } = useOrgData();
    const [isEditing, setIsEditing] = useState<boolean>(props.isEditing ?? false);
    
    async function onSubmitSport(e: React.FormEvent<HTMLFormElement>, formData: GenericObject): Promise<boolean> {
        try {
            await apiCall<Sport>(`/sports/save`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    sport: {
                        ...props.sport,
                        name: formData["name"],
                        organizationId: org?.id,
                        archived: formData["archived"]
                    }
                })
            });

            setAlertMessage(`The sport has been ${props.sport?.id ? "modified" : "created"}.`, AlertTypeEnum.SUCCESS);
            setIsEditing(false);
            reloadSportData();

            if (props.onFormSubmitted) {
                props.onFormSubmitted();
            }

            return true;
        }
        catch(err) {
            setAlertMessage(`There was an error saving the sport: ${(err as Error).message}`, AlertTypeEnum.ERROR);
            return false;
        }
    }

    function onCancelSport() {
        setIsEditing(false);

        if (props.onFormSubmitted) {
            props.onFormSubmitted();
        }
    }

    return (
        <Card className="sport-form display-only">
            <Card.Content>
                {
                    isEditing ? (
                        <Form onSubmitClicked={onSubmitSport} onCancelClicked={onCancelSport}>
                            <FormRow className="full">
                                <InputField type="text" name="name" defaultValue={props.sport?.name} placeholder={"New Sport Name"} />
                            </FormRow>
                            <FormRow>
                                <CheckboxField type="switch" name="archived" label="Archive?" checked={props.sport?.archived ?? false} />
                            </FormRow>
                        </Form>
                    ) : (
                        <>
                            {props.sport?.name} 
                            <EditIcon className="icon" onClick={() => setIsEditing(true)} />
                        </>
                    )
                }
            </Card.Content>
            {
                props.sport?.archived && (
                    <Card.Footer>
                        Archived
                    </Card.Footer>
                )
            }
        </Card>
    )
}

export default SportForm;
