import { useEffect, useState } from "react";
import clsx from "clsx";
import Modal from "../../commmon/Modal";
import Card from "../../commmon/Card";
import Button from "../../commmon/Button";
import Loading from "../../commmon/Loading";
import { apiCall } from "../../../functions";
import { useAlert } from "../../../context/AlertContext";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";
import CheckboxField from "../../fields/CheckboxField";
import { useOrgData } from "../../../context/OrgContext";


type LeagueAppsModalProps = Readonly<{
    onClose: () => void
}>

const LeagueAppsModal = (props: LeagueAppsModalProps) => {
    const { org, season } = useOrgData();
    const { setAlertMessage } = useAlert();
    const [apiPrograms, setPrograms] = useState<any[]>([]);
    const [selectedProgram, setSelectedProgram] = useState<number>();
    const [warningKnown, setWarningKnown] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        apiCall<any[]>(`/integration/leagueapps/programs?orgId=${org?.id}`)
        .then((resp) => {
            setPrograms(resp.data);
        })
        .catch((err) => {
            setAlertMessage(err.message, AlertTypeEnum.ERROR);
            console.log(err);
        })
        .finally(() => setIsLoading(false))
    }, [])

    function onImportProgramButtonClicked() {
        if (selectedProgram) {
            setIsLoading(true);
            apiCall<boolean>(`/integration/leagueapps/import`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    orgId: org?.id,
                    seasonId: season?.id,
                    programId: selectedProgram
                })
            })
            .then((resp) => {
                if (resp.data) {
                    setAlertMessage(`The season was successfully imported.`, AlertTypeEnum.SUCCESS);
                    props.onClose();
                }
                else {
                    setAlertMessage(`No data was returned from the server.`, AlertTypeEnum.WARNING);
                }
            })
            .catch((err) => {
                setAlertMessage(err.message, AlertTypeEnum.ERROR);
            })
            .finally(() => setIsLoading(false))
        }
        else {
            setAlertMessage("A program must be selected before importing", AlertTypeEnum.ERROR);
        }
    }

    return (
        <Modal onModalClose={props.onClose}>
            <div>
                <h3 className="center">Current Programs In LeagueApps</h3>
            </div>
            {
                isLoading ? (
                    <Loading />
                ) : (
                    apiPrograms.map((p) => (
                        <Card 
                            key={p.programId}
                            onClick={() => setSelectedProgram(Number(p.programId))}
                            className={clsx(
                                "leagueapps-program",
                                "button",
                                Number(selectedProgram) === Number(p.programId) ? "selected" : ""
                            )} 
                        >
                            <Card.Header>
                                {p.name}
                            </Card.Header>
                            <Card.Content>
                                <div className="center">
                                    {p.season} {p.sport}
                                </div>
                            </Card.Content>
                            <Card.Footer>
                                {p.state}
                            </Card.Footer>
                        </Card>
                    ))
                )
            }
            <div>
                <CheckboxField
                    name="warning"
                    type="checkbox"
                    checked={warningKnown}
                    onClick={(isChecked) => setWarningKnown(isChecked)}
                    label="You understand, this operation will destroy all existing player responses, teams, and players."
                />
            </div>
            <div>
                <Button 
                    className="full active" 
                    type="button" 
                    disabled={!selectedProgram || !warningKnown || isLoading} 
                    onClick={onImportProgramButtonClicked}
                >
                    Import Program Data
                </Button>
            </div>
        </Modal>
    )
}

export default LeagueAppsModal;