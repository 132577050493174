import { ReactNode, createContext, useContext, useEffect, useState } from "react"
import { apiCall } from "../functions";
import { PermissionLevel } from "../enum/PermissionLevelEnum";
import { useLocation } from "react-router-dom";

type OrgContextProps = {
    loadData: (orgId: number | string | undefined, seasonId?: number | string | undefined) => Promise<boolean>
    reloadSeasonData: () => Promise<boolean>
    reloadRefereeData: () => Promise<boolean>
    reloadTeamData: () => Promise<boolean>
    reloadAwardData: () => Promise<boolean>
    reloadOrgData: () => Promise<boolean>,
    reloadSportData: () => Promise<boolean>,
    getUserPermissionLevel: (user: User|undefined) => PermissionLevel
    toggleWinnersOnly: () => void
    awardData: undefined | {
        awards: AwardSection[],
        votes: any[],
        nonVoters: {
            teams: Team[],
            totalNonVoters: number
        }
    }
    org: Organization | undefined,
    refs: TeamPlayers[]
    teams: Team[]
    season?: Season
    winnersOnly: boolean,
    sportTypes: Sport[]
    seasonTypes: SeasonType[]
    questionTypes: QuestionType[]
    integrations: Integration[]
}

type OrgProviderProps = {
    children: ReactNode
}

const OrgContext = createContext({} as OrgContextProps);

export function OrgProvider(props: OrgProviderProps) {
    const { pathname } = useLocation();
    const [org, setOrg] = useState<Organization|undefined>();
    const [season, setSeason] = useState<Season>();
    const [winnersOnly, setWinnersOnly] = useState<boolean>(false);

    // non-changing data
    const [sportTypes, setSportTypes] = useState<Sport[]>([]);
    const [seasonTypes, setSeasonTypes] = useState<SeasonType[]>([]);
    const [questionTypes, setQuestionTypes] = useState<QuestionType[]>([]);
    const [integrations, setIntegrarions] = useState<Integration[]>([]);

    // Changing data
    const [refs, setRefs] = useState<TeamPlayers[]>([]);
    const [teams, setTeams] = useState<Team[]>([]);
    const [awardData, setAwardData] = useState<{
        awards: AwardSection[],
        votes: any[],
        nonVoters: {
            teams: Team[],
            totalNonVoters: number
        }
    } | undefined>();

    useEffect(() => {
        getSeasonTypes();
        getQuestionTypes();
    }, [])

    useEffect(() => {
        if (pathname !== "/season/vote") {
            getIntegrations();
            getSportTypes();
        }
    }, [org])

    useEffect(() => {
        if (pathname !== "/season/vote") {
            loadSeasonData();
        }
    }, [season])

    async function loadData(orgId: number | string | undefined, seasonId: number | string | undefined): Promise<boolean> {
        await setOrganization(orgId);
        await setSeletedSeason(seasonId);
        await reloadSportData();
        return true;
    }

    async function setOrganization(orgId: number | string | undefined): Promise<void> {
        if (!isNaN(Number(orgId))) {
            const loadedOrg = await apiCall<Organization>(`/organization/${orgId}`);
            setOrg(loadedOrg.data);
            await getIntegrations();
        }
        else {
            setOrg(undefined);
        }
    }

    async function setSeletedSeason(selectedSeason: Season | string | number | undefined): Promise<boolean> {
        setRefs([]);
        setTeams([]);
        setAwardData(undefined);
        let seasonId: number | undefined = !isNaN(Number(selectedSeason)) ? Number(selectedSeason) : undefined;

        if (seasonId) {
            const loadedSeason = (await apiCall<Season>(`/seasons/?seasonId=${selectedSeason}`)).data;

            await setOrganization(loadedSeason.organizationId);
            setSeason(loadedSeason);
        }
        else {
            setSeason(undefined);
        }
        
        return true;
    }

    async function getSportTypes(): Promise<Sport[]> {
        if (org) {
            const allSportTypes = await apiCall<ApiDataResponse<Sport[]>>(`/sports/${org.id}/all`);
            setSportTypes(allSportTypes.data.data || []);
            return allSportTypes.data.data || [];
        }
        else {
            return [];
        }
    }

    async function getSeasonTypes(): Promise<SeasonType[]> {
        const allSeasonTypes = await apiCall<ApiDataResponse<SeasonType[]>>(`/seasontype/all`);
        setSeasonTypes(allSeasonTypes.data.data || []);
        return allSeasonTypes.data.data || [];
    }

    async function getQuestionTypes(): Promise<QuestionType[]> {
        const allQuestionTypes = await apiCall<ApiDataResponse<QuestionType[]>>(`/questiontypes/all`);
        setQuestionTypes(allQuestionTypes.data.data || []);
        return allQuestionTypes.data.data || [];
    }

    async function getIntegrations(): Promise<Integration[]> {
        if (org) {
            const allIntegrations = await apiCall<Integration[]>(`/organization/${org.id}/integrations`);
            setIntegrarions(allIntegrations.data);
            return allIntegrations.data;
        }
        else { 
            return [];
        }
    }

    async function reloadSeasonData(): Promise<boolean> {
        return await loadSeasonData();
    }

    async function reloadOrgData(): Promise<boolean> {
        if (org) {
            const orgData = await apiCall<Organization>(`/organization/${org.id}`)
            setOrg(orgData.data);
            return true;
        }
        else {
            return false;
        }
    }

    async function loadSeasonData(): Promise<boolean> {
        await reloadTeamData();
        await reloadAwardData();
        await reloadRefereeData();
        
        return true;
    }

    async function reloadRefereeData(): Promise<boolean> {
        if (season) {
            const refData = await apiCall<TeamPlayers[]>(`/referees/${season.id}`);
            setRefs(refData.data);
        }
        else {
            setRefs([]);
        }

        return true;
    }

    async function reloadTeamData(): Promise<boolean> {
        if (season) {
            const seasonalTeams = await apiCall<ApiDataResponse<Team[]>>(`/teams/${season.id}/all`);
            setTeams(seasonalTeams.data.data || []);
        }
        else {
            setTeams([]);
        }

        return true;
    }

    async function reloadAwardData(): Promise<boolean> {
        if (season) {
            const awardData = await apiCall<ApiDataResponse<any>>(`/awards/season/${season.id}/admin/results`);
            setAwardData(awardData.data.data);
        }
        else {
            setAwardData(undefined);
        }

        return true;
    }

    async function reloadSportData(): Promise<boolean> {
        getSportTypes();
        return true;
    }

    function getUserPermissionLevel(user: User|undefined): PermissionLevel {
        if (user && org) {
            const foundUser = org.users.find(x => x.userId === user.id);
            return foundUser?.permissionLevel || PermissionLevel.NONE;
        }
        else {
            return PermissionLevel.NONE;
        }
    }

    function toggleWinnersOnly() {
        setWinnersOnly(!winnersOnly);
    }

    return (
        <OrgContext.Provider value={{
            toggleWinnersOnly,
            loadData,
            reloadSportData,
            reloadSeasonData,
            reloadRefereeData,
            reloadTeamData,
            reloadAwardData,
            reloadOrgData,
            getUserPermissionLevel,
            winnersOnly,
            org,
            season,
            teams,
            refs,
            awardData,
            sportTypes,
            seasonTypes,
            questionTypes,
            integrations
        }}>
            { props.children }
        </OrgContext.Provider>
    )
}

export function useOrgData() {
    return useContext(OrgContext);
}
