import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const Logout = () => {
    const { logout } = useAuth();
    const nav = useNavigate();

    useEffect(() => {
        logout();
        setTimeout(() => {
            nav("/");
        }, 1000)
    }, [logout, nav])

    return (
        <div className="center">
            <p>You have been logged out.</p>
            <p>Redirecting you to the login page...</p>
        </div>
    )
}

export default Logout;