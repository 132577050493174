import { useOrgData } from "../../../context/OrgContext";
import NomineeDisplay from "./NomineeDisplay";

type QuestionLeagueVotesProps = Readonly<{
    json: AdminVoteData<
        Array<{
            tp_id: number,
            tp_teamId: number,
            tp_playerId: number,
            p_firstName: string,
            p_lastName: string,
            p_aka: string,
            t_name: string,
            votes: number,
            rank: number
        }>
    >
}>;

const QuestionLeagueVotes = function(props: QuestionLeagueVotesProps) {
    const { winnersOnly } = useOrgData();

    const winningPlayers = props.json.data.filter(x => props.json.winners.some(w => w.teamPlayersId === x.tp_id));
    let players = props.json.data;
    
    if (winnersOnly) {
        if (winningPlayers.length > 0) {
            players = winningPlayers;
        }
        else {
            players = props.json.data.slice(0, 1);
        }
    }

    return (
        <div className="nominees">
            {
                players.map((nominee, i) => (
                    <NomineeDisplay 
                        key={`${i}`} 
                        nominee={nominee} 
                        questionId={props.json.questionId}
                        showTeamName={false} 
                        isWinner={props.json.winners.some(x => x.teamPlayersId === nominee["tp_id"])}
                    />
                ))
            }
        </div>
    )
}

export default QuestionLeagueVotes;