import { createRef, useEffect, useState } from "react";
import Form from "../../commmon/Form";
import FormRow from "../../commmon/FormRow";
import Modal from "../../commmon/Modal";
import CheckboxField from "../../fields/CheckboxField";
import AwardCertificate from "../AwardCertificate";
import { useAlert } from "../../../context/AlertContext";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";

type PrintAwardsModalProps = Readonly<{
    seasonId?: number,
    onClose: (pdfString?: Blob) => void
}>

const PrintAwardsModal = (props: PrintAwardsModalProps) => {
    const downloadRef = createRef<HTMLAnchorElement>();
    const { setAlertMessage } = useAlert();
    const [printStarredOnly, setPrintStarredOnly] = useState<boolean>(false);

    async function onSubmitClicked(): Promise<boolean> {
        try {
            const results = await fetch(`/awards/print?seasonId=${props.seasonId}&starredOnly=${printStarredOnly}`, {
                method: "GET"
            });
            const pdfData = await results.text();
            const aRef = downloadRef.current;
            if (aRef) {
                aRef.download = results.headers.get("X-FileName")!;
                aRef.href = `data:application/pdf;base64,${pdfData}`;
                aRef.click();
            }
            
            return true;
        }
        catch(e) {
            setAlertMessage((e as Error).message, AlertTypeEnum.ERROR);
            return false;
        }
    }

    return (
        <Modal onModalClose={props.onClose}>
            <section>
                {/* <AwardCertificate />
                <div className="center">Sample</div> */}
                <Form onSubmitClicked={onSubmitClicked} onCancelClicked={props.onClose}>
                    {/* <FormRow className="full">
                        <CheckboxField 
                            name="starredOnly" 
                            type="checkbox" 
                            checked={printStarredOnly} 
                            onClick={() => setPrintStarredOnly(!printStarredOnly)}
                            label="Print only starred winners"  
                        />
                    </FormRow> */}
                </Form>
                <a className="hide" ref={downloadRef} />
            </section>
        </Modal>
    )
}

export default PrintAwardsModal;
