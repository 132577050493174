import { ReactNode } from "react";
import { ReactComponent as WarningIcon } from "../../svgs/warning.svg";
import { ReactComponent as DangerIcon } from "../../svgs/notification.svg";
import { ReactComponent as InfoIcon } from "../../svgs/info.svg";

import "../../styles/scss/info-label.scss";

type InfoLabelProps = Readonly<{
    type: "info" | "warning" | "danger"
    children: ReactNode
}>

const InfoLabel = (props: InfoLabelProps) => {
    return (
        <div className={`labeler ${props.type}-label`}>
            <div className="icon">
                {
                    props.type === "warning" && (
                        <WarningIcon />
                    )
                }
                {
                    props.type === "danger" && (
                        <DangerIcon />
                    )
                }
                {
                    props.type === "info" && (
                        <InfoIcon />
                    )
                }
            </div>
            <div className="labeler-content">
                { props.children }
            </div>
        </div>
    )
}

export default InfoLabel;
