import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from "../context/AuthContext";
import { AlertProvider } from '../context/AlertContext';
import { OrgProvider } from '../context/OrgContext';
import { SiteInfoProvider } from '../context/SiteInfoContext';
import Vote from "./Vote";
import Login from "./Login";
import RequireAuth from './RequireAuth';
import SeasonSelect from './SeasonSelect';
import OrgMain from './organization/Main';
import Header from './Header';
import Footer from './Footer';
import NotFound404 from './pages/NotFound404';
import Logout from './Logout';
import AdminMain from './admin/Main';
import UserDashboard from './UserDashboard';

import "../styles/scss/app.scss";

const App = function() {
    return (
        <div id="app">
            <SiteInfoProvider>
                <AlertProvider>
                    <AuthProvider>
                        <BrowserRouter>
                            <OrgProvider>
                                <Header />
                                <div id="app-content">
                                    <Routes>
                                        <Route path="/" index element={<Login />} />
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/logout" element={<Logout />}/>
                                        <Route path="/dashboard" element={<UserDashboard />} />
                                        <Route path="/admin/*" element={
                                            <RequireAuth>
                                                <AdminMain />
                                            </RequireAuth>
                                        } />
                                        <Route path="/org/*" element={
                                            <RequireAuth>
                                                <OrgMain />
                                            </RequireAuth>
                                        } />
                                        <Route index path="/season" element={
                                            <RequireAuth>
                                                <SeasonSelect />
                                            </RequireAuth>
                                        } />
                                        <Route path="/season/vote" element={
                                            <RequireAuth>
                                                <Vote />
                                            </RequireAuth>
                                        } />
                                        <Route path="*" element={
                                            <NotFound404 />
                                        } />
                                    </Routes>
                                </div>
                            </OrgProvider>
                        </BrowserRouter>
                    </AuthProvider>
                </AlertProvider>
                <Footer />
            </SiteInfoProvider>
        </div> 
    )
}

export default App;
