import {useRef, useEffect} from "react";

type Timeout = ReturnType<typeof setTimeout>;
type SomeFunction = (...args: any[]) => void;

function useDebounce<Func extends SomeFunction>(callback: Func, delay: number = 1000) {
    const timer = useRef<Timeout>();

    useEffect(() => {
        return () => {
            if (!timer.current) {
                return;
            }

            clearTimeout(timer.current);
        }
    }, [])

    function debounce(...args: any[]) {
        const newTimer = setTimeout(() => {
            callback(args);
        }, delay);
        
        clearTimeout(timer.current);
        timer.current = newTimer;
    }

    return debounce;
}

export default useDebounce;