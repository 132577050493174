import { useEffect, useState } from "react";
import { useOrgData } from "../../context/OrgContext";
import {ReactComponent as EditIcon} from "../../svgs/pencil.svg";
import TeamPlayerForm from "./forms/TeamPlayerForm";

import "../../styles/scss/admin-team-player.scss";

type SeasonPlayer = Readonly<{
    teamPlayer: TeamPlayers
    teamId: number,
    onHideNewPlayerForm?: () => void
}>

const SeasonPlayer = function(props: SeasonPlayer) {
    const { reloadTeamData } = useOrgData();
    const [isEditingPlayer, setIsEditingPlayer] = useState<boolean>(false);

    useEffect(() => {
        if (!props.teamPlayer.id) {
            setIsEditingPlayer(true);
        }
    }, [])

    function onPlayerEditClicked() {
        setIsEditingPlayer(true);
    }

    function onFormComplete() {
        if (props.onHideNewPlayerForm) {
            props.onHideNewPlayerForm();
        }

        setIsEditingPlayer(false);
        reloadTeamData();
    }

    return (
        <div className="admin-team-player">
            {
                isEditingPlayer ? (
                    <TeamPlayerForm
                        teamPlayer={{
                            ...props.teamPlayer,
                            teamId: props.teamId
                        }}
                        onFormSuccess={onFormComplete}
                        onFormCancel={onFormComplete}
                    />
                ) : (
                    <div className="player-data">
                        <div className="player-name">
                            {props.teamPlayer.player?.firstName} {props.teamPlayer.player?.lastName}
                            <br/>
                            <sub>
                                {props.teamPlayer.captian ? `Captain` : ``}
                            </sub>
                        </div>
                        <div className="buttons">
                            <EditIcon className="icon" onClick={onPlayerEditClicked} />
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default SeasonPlayer;