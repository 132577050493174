import InputField, { InputFieldProps } from "./InputField"

type FileUploadFieldProps = Omit<InputFieldProps, "type"> & {
    label: string,
    name: string,
    accept: string
}

const FileUploadField = (props: FileUploadFieldProps) => {
    return (
        <InputField type="file" {...props} />
    )
}

export default FileUploadField;
