import { useEffect, useState } from "react";
import { apiCall } from "../../functions";
import Card from "../commmon/Card";
import Button from "../commmon/Button";
import OrgForm from "./forms/OrgForm";
import { useNavigate } from "react-router-dom";

const Organizations = () => {
    const nav = useNavigate();
    const [orgs, setOrgs] = useState<Organization[]>([]);
    const [showOrgForm, setShowOrgForm] = useState<boolean>(false);

    useEffect(() => {
        onReloadOrganizations();
    }, []);

    function onReloadOrganizations() {
        apiCall<Organization[]>(`/organization`)
        .then((json) => {
            setOrgs(json.data);
        })
    }

    function onFormSuccess() {
        onReloadOrganizations();
        setShowOrgForm(false)
    }
    
    function onFormCancel() {
        setShowOrgForm(false)
    }

    return (
        <div>
            {
                orgs.map((org) => (
                    <Card key={org.id} onClick={() => nav(`${org.id}`)}>
                        <Card.Header>{org.name}</Card.Header>
                        <Card.Footer>
                            {
                                org.deletedDate ? "Deleted" : "Active"
                            }
                        </Card.Footer>
                    </Card>
                ))
            }
            {
                showOrgForm ? (
                    <OrgForm onSuccess={onFormSuccess} onCancel={onFormCancel} />
                ) : (
                    <Button className="full" onClick={() => setShowOrgForm(true)}>
                        Add New Organization
                    </Button>
                )
            }
        </div>
    )
}

export default Organizations;
