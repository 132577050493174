import { ChangeEvent, useState } from "react"

type SelectFieldProps = Readonly<{
    label: string
    children: React.HTMLProps<HTMLOptionElement>[] | React.HTMLProps<HTMLOptionElement>
}>

const SelectField = ({children, label, ...props}: SelectFieldProps & React.SelectHTMLAttributes<HTMLSelectElement>) => {
    const [value, setValue] = useState<any>(props.value)

    function setSelectedValue(e: ChangeEvent<HTMLSelectElement>) {
        setValue(e.currentTarget.value);

        if (props.onChange) {
            props.onChange(e);
        }
    }

    return (
        <div className="select-field">
            <label>
                {label}
            </label>
            <select {...props} value={value} onChange={setSelectedValue}>
                <option value=""></option>
                {children}
            </select>
        </div>
    )
}

export default SelectField;