import { useEffect, useState } from "react";
import { apiCall } from "../../functions";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../commmon/Button";
import { useAlert } from "../../context/AlertContext";
import AlertTypeEnum from "../../enum/AlertTypeEnum";
import UserManager from "../commmon/UserManager";
import { useOrgData } from "../../context/OrgContext";

const OrganizationView = () => {
    const { orgId } = useParams();
    const { org, loadData } = useOrgData();
    const { setAlertMessage } = useAlert();
    const nav = useNavigate();

    useEffect(() => {
        onRefreshOrgData();
    }, [])

    function onRefreshOrgData() {
        loadData(orgId);
    }

    function onDeleteClicked() {
        if (window.confirm("Are you sure you want to delete this organization?")) {
            apiCall(`/organization/delete`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id: org?.id
                })
            })
            .then((json) => {
                if (json) {
                    setAlertMessage(`The organization has been deleted`, AlertTypeEnum.SUCCESS);
                    nav(`/admin/organizations/`)
                }
                else {
                    setAlertMessage(`There was an issue deleting this organization`, AlertTypeEnum.ERROR);
                }
            })
            .catch((err) => {
                setAlertMessage(`There was an issue with deleting this organization. ${err}`, AlertTypeEnum.ERROR);
            })
        }
    }

    return (
        <div>
            <h2>{org?.name}</h2>
            <section>
                <UserManager />
            </section>
            <section>
                <Button className="danger" onClick={onDeleteClicked}>Delete</Button>
                <Button>Save</Button>
            </section>
        </div>
    )
}

export default OrganizationView;
