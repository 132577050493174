import { FormEvent, ReactNode, useState } from "react";
import clsx from "clsx";
import { useOrgData } from "../../../context/OrgContext";
import Form from "../../commmon/Form";
import FormRow from "../../commmon/FormRow";
import FileUploadField from "../../fields/FileUploadField";
import InputField from "../../fields/InputField";
import { apiCall } from "../../../functions";
import { useAlert } from "../../../context/AlertContext";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";
import Card from "../../commmon/Card";
import SportForm from "./SportForm";

import "../../../styles/scss/forms/OrgSettings.scss";

type SettingsFormProps = Readonly<{
    children?: ReactNode
}>

enum FormDisplay {
    SETTINGS,
    CERTIFICATE,
    SPORTS
}

const SettingsForm = (props: SettingsFormProps) => {
    const { setAlertMessage } = useAlert();
    const { org, sportTypes, reloadOrgData } = useOrgData();
    const [formDisplay, setFormDisplay] = useState<FormDisplay>(FormDisplay.SETTINGS);
    const [showSportForm, setShowSportForm] = useState<boolean>(false);

    async function onSubmit(e: FormEvent<HTMLFormElement>, data: GenericObject): Promise<boolean> {
        const formData: FormData = new FormData();
        if (data.logo) {
            formData.append("photo", data.logo || null);
        }
        if (data.certificate) {
            formData.append("certificate", data.certificate || null);
        }

        formData.append("organization", JSON.stringify({
            id: org?.id,
            name: org?.name,
            location: org?.location,
            rookieMax: data.rookie
        }));

        try {
            await apiCall<Organization>(`/organization/save`, {
                method: "POST",
                body: formData
            });

            reloadOrgData();
            setAlertMessage(`Organization settings were saved.`, AlertTypeEnum.SUCCESS);
            return true;
        }
        catch(err) {
            console.error(err);
            setAlertMessage(`There was an error in saving the settings. Try again later.`, AlertTypeEnum.ERROR);
            return false;
        }
    }

    return (
        <div id="org-settings">
            <Card className={clsx("display-only", formDisplay === FormDisplay.SETTINGS ? "show" : "hide")} onClick={() => setFormDisplay(FormDisplay.SETTINGS)}>
                <Card.Header>
                    Settings
                    <div className={`collapsible-menu-button ${formDisplay === FormDisplay.SETTINGS ? `expanded` : `collapsed`}`} />
                </Card.Header>
                <Card.Content>
                    <Form onSubmitClicked={onSubmit}>
                        <FormRow className="full">
                            <InputField name="name" label="Name" defaultValue={org?.name} disabled />
                        </FormRow>
                        <FormRow className="full">
                            <InputField name="location" label="Location" defaultValue={org?.location} disabled />
                        </FormRow>
                        <FormRow className="full">
                            <InputField name="rookie" type="number" label="A Rookie is a player who has played in a specific sport no more than....?" min={1} max={10} defaultValue={org?.rookieMax} />
                        </FormRow>
                        <FormRow className="full">
                            <FileUploadField name="logo" label="Logo" accept={".png,.jpg,.jpeg,.svg"} />
                        </FormRow>
                    </Form>
                </Card.Content>
            </Card>
            <Card className={clsx("display-only", formDisplay === FormDisplay.CERTIFICATE ? "show" : "hide")} onClick={() => setFormDisplay(FormDisplay.CERTIFICATE)}>
                <Card.Header>
                    Certificates
                    <div className={`collapsible-menu-button ${formDisplay === FormDisplay.CERTIFICATE ? `expanded` : `collapsed`}`} />
                </Card.Header>
                <Card.Content>
                    <Form onSubmitClicked={onSubmit}>
                        <FormRow className="full">
                            <FileUploadField name="certificate" label="Award Certificate" accept={".svg"} />
                        </FormRow>
                    </Form>
                </Card.Content>
            </Card>
            <Card className={clsx("settings-sports", "display-only", formDisplay === FormDisplay.SPORTS ? "show" : "hide")} onClick={() => setFormDisplay(FormDisplay.SPORTS)}>
                <Card.Header>
                    Sport Types
                    <div className={`collapsible-menu-button ${formDisplay === FormDisplay.SPORTS ? `expanded` : `collapsed`}`} />
                </Card.Header>
                <Card.Content>
                    <ul>
                        {
                            sportTypes.map((sport) => (
                                <li key={`sport-${sport.id}`}>
                                    <SportForm sport={sport} />
                                </li>
                            ))
                        }
                    </ul>
                    {
                        !showSportForm ? (
                            <Card className="full" onClick={() => setShowSportForm(true)}>
                                <Card.Header>
                                    Add Sport
                                </Card.Header>
                            </Card>
                        ) : (
                            <Card>
                                <Card.Header>New Sport</Card.Header>
                                <SportForm isEditing={true} onFormSubmitted={() => setShowSportForm(false)}/>
                            </Card>
                        )
                    }
                </Card.Content>
            </Card>
        </div>
    )
}

export default SettingsForm;
