import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { apiRequest } from "../../functions";
import { TeamDivision } from "../../enum/TeamDivision";
import { useAlert } from "../../context/AlertContext";
import Button from "../commmon/Button";
import Card from "../commmon/Card";
import SeasonPlayer from "./SeasonPlayer";
import TeamForm from "./forms/TeamForm";
import { useOrgData } from "../../context/OrgContext";
import AlertTypeEnum from "../../enum/AlertTypeEnum";

import { ReactComponent as EditIcon } from "../../svgs/pencil.svg";
import "../../styles/scss/admin-team.scss";

type SeasonTeamProps = Readonly<{
    team: Team
    onHideNewTeamForm?: () => void
}>

const SeasonTeam = function({ team, ...props }: SeasonTeamProps) {
    const { reloadTeamData } = useOrgData();
    const { seasonId } = useParams();
    const { setAlertFromApiMessage, setAlertMessage } = useAlert();
    const [showPlayerForm, setShowPlayerForm] = useState<boolean>(false);
    const [showPlayers, setShowPlayers] = useState<boolean>(false);
    const [photoCount, setPhotoCount] = useState<number>(0);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const imageRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!team.id && team.teamPlayers.length === 0) { 
            setIsEditing(true);
        }
    }, [])
 
    function onToggleShowPlayers() {
        if (!isEditing) {
            setShowPlayers(!showPlayers);
        }
    }

    function onTeamPhotoChange(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.files && seasonId && team.id) {
            const formData: FormData = new FormData();
            formData.append("photo", e.target.files[0])
            formData.append("seasonId", seasonId?.toString());
            formData.append("teamId", team.id.toString())

            apiRequest<Team>(`/teams/upload/photo`, {
                method: "POST",
                body: formData
            })
            .then((json) => {
                setAlertFromApiMessage(json);
                setPhotoCount((count) => count + 1);
            })
            .catch((err) => {
                console.error(err);
                setAlertMessage(`An unexpected error occured when uploading your photo`, AlertTypeEnum.ERROR);
            })
        }
    }

    function onEditIconClicked(e: MouseEvent<SVGSVGElement>) {
        e.preventDefault();
        e.stopPropagation();

        if (team.id) {
            setIsEditing(true);
            setShowPlayers(false);
        }
    }

    function onFormSuccess() {
        if (props.onHideNewTeamForm) {
            props.onHideNewTeamForm();
        }
        setIsEditing(false);
        reloadTeamData();
    }

    function onFormCancel() {
        if (props.onHideNewTeamForm) {
            props.onHideNewTeamForm();
        }
        setIsEditing(false);
    }

    return (
        <Card className="season-team display-only" onClick={onToggleShowPlayers}>
            {
                isEditing ? (
                    <TeamForm 
                        seasonId={seasonId}
                        team={team}
                        onFormSuccess={onFormSuccess}
                        onFormCancel={onFormCancel}
                    />
                ) : (
                    <>
                        <Card.Header>
                            <h3>
                                <div className="team-name">
                                    <div className="name">
                                        {team.name} 
                                    </div>
                                    {
                                        team.id && (
                                            <EditIcon className="icon" onClick={onEditIconClicked} />
                                        )
                                    }
                                </div>
                                <div className={`collapsible-menu-button ${showPlayers ? `expanded` : `collapsed`}`} />
                            </h3>
                        </Card.Header>
                        <Card.Content>
                            <div className={`season-team-players ${showPlayers ? "content-show" : "content-hide"}`} onClick={(e) => e.stopPropagation()}>
                                <div className="team-photo">
                                    <div className="file-input">
                                        <input ref={imageRef} type="file" onChange={onTeamPhotoChange} />
                                    </div>
                                    <div className={`display ${team.imagePath ? "valid-image" : "no-image"}`}>
                                        <div className="text-overlay">
                                            Click to change photo
                                        </div>
                                        <img 
                                            src={team.imagePath ? `${team.imagePath}?count=${photoCount}` : `https://via.placeholder.com/500x50/CCCCCC/000000/?text=Click to add an image`} 
                                            alt="Upload a photo"
                                            onClick={() => imageRef.current?.click()}
                                        />
                                    </div>
                                </div>
                                {
                                    team.teamPlayers.map(x => (
                                        <SeasonPlayer 
                                            key={`player-${x.id}`} 
                                            teamPlayer={x} 
                                            teamId={team.id}
                                        />
                                    ))
                                }
                                {
                                    showPlayerForm ? (
                                        <SeasonPlayer 
                                            key={`player-new}`} 
                                            teamPlayer={{ player: {} } as TeamPlayers} 
                                            teamId={team.id}
                                            onHideNewPlayerForm={() => setShowPlayerForm(false)}
                                        />
                                    ) : (
                                        <Button className={`card center`} onClick={() => setShowPlayerForm(true)}>
                                            Add Player
                                        </Button>
                                    )
                                }
                            </div>
                        </Card.Content>
                    </>
                )
            }
            <Card.Footer>
                <div>
                    {
                        team.division ? (
                            <>{TeamDivision[team.division].toString()} - </>
                        ) : null
                    } {team.teamPlayers.length} players
                </div>
            </Card.Footer>
        </Card>
        
    )

}

export default SeasonTeam;