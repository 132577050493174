import { useEffect } from "react";

const useOutsideEvent = (ref: React.RefObject<any>, callback: () => void) => {
    
    useEffect(() => {
        document.addEventListener("mousedown", handleClick, true);

        return () => {
            document.removeEventListener("mousedown", handleClick, true);
        }
    }, [ref])

    function handleClick(e: MouseEvent) {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    }

}

export default useOutsideEvent;
