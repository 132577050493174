import { Link } from "react-router-dom";
import Card from "../commmon/Card";
import { ReactComponent as HomeIcon } from "../../svgs/home.svg";
import PasswordResetForm from "./forms/PasswordResetForm";

const Profile = () => {
    function onFormEmptyHandle() { }

    return (
        <div id="admin-profile">
            <h3>
                <Link to="../">
                    <HomeIcon className="icon" />
                </Link> - Profile
            </h3>
            <section>
                <Card className="display-only">
                    <Card.Header>
                        <h3>Password Update</h3>
                    </Card.Header>
                    <Card.Content>
                        <PasswordResetForm 
                            onFormSuccess={onFormEmptyHandle}
                            onFormCancel={onFormEmptyHandle}
                        />
                    </Card.Content>
                </Card>
            </section>
        </div>
    )
}

export default Profile;