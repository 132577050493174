import { Link } from "react-router-dom";

const AwardNav = function(props: ClickableElement) {

    return (
        <>
            <Link className="link" to="/season" onClick={props.onElementClicked}>Season Selection</Link>
        </>
    )

}

export default AwardNav;