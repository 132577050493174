import { FormEvent } from "react";
import Form from "../../commmon/Form";
import FormRow from "../../commmon/FormRow";
import InputField from "../../fields/InputField";
import { apiCall } from "../../../functions";
import { useAlert } from "../../../context/AlertContext";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";
import Card from "../../commmon/Card";

type OrgFormProps = Readonly<{
    org?: Organization
    onSuccess: () => void
    onCancel: () => void
}>

const OrgForm = (props: OrgFormProps) => {
    const { setAlertMessage } = useAlert();

    async function onSubmit(e: FormEvent<HTMLFormElement>, formData: GenericObject): Promise<boolean> {
        try {
            const json = await apiCall(`/organization/create`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    org: {
                        name: formData.name,
                        location: formData.location
                    },
                    user: {
                        email: formData.email,
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        password: formData.tempPass
                    }
                })
            })

            if (json.resp) {
                setAlertMessage(`The organization was created successfully.`, AlertTypeEnum.SUCCESS);
                props.onSuccess();
                return true;
            }
            else {
                setAlertMessage(`There was an error in creating the organization. Please check the logs.`, AlertTypeEnum.ERROR);
                return false;
            }
        }
        catch(err) {
            console.error(err);
            setAlertMessage(`There was an error in creating the organization. Please check the logs.`, AlertTypeEnum.ERROR);
            return false;
        }
    }

    async function onDelete(): Promise<boolean> {
        try {
            const json = await apiCall(`/organization/delete`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id: props.org?.id
                })
            })

            if (json.resp) {
                setAlertMessage(`The organization was deleted successfully.`, AlertTypeEnum.SUCCESS);
                props.onSuccess();
                return true;
            }
            else {
                setAlertMessage(`There was an error in deleting the organization. Please check the logs.`, AlertTypeEnum.ERROR);
                return false;
            }
        }
        catch(err) {
            console.error(err);
            setAlertMessage(`There was an error in deleting the organization. Please check the logs.`, AlertTypeEnum.ERROR);
            return false;
        }
    }

    return (
        <Card className="display-only">
            <Card.Content>
                <Form 
                    onSubmitClicked={onSubmit} 
                    onCancelClicked={props.onCancel} 
                    onDeleteClicked={onDelete}
                    showDelete={props.org?.id !== undefined}
                >
                    <FormRow className="full">
                        <InputField type="text" name="name" label="Name" defaultValue={props.org?.name} />
                    </FormRow>
                    <FormRow className="full">
                        <InputField type="text" name="location" label="Location" defaultValue={props.org?.location} />
                    </FormRow>
                    <FormRow className="full">
                        <InputField type="text" name="email" label="Owner Email" />
                    </FormRow>
                    <FormRow>
                        <InputField type="text" name="firstName" label="Owner First Name" />
                    </FormRow>
                    <FormRow>
                        <InputField type="text" name="lastName" label="Owner Last Name" />
                    </FormRow>
                    <FormRow>
                        <InputField type="password" name="tempPass" label="Temp Password" />
                    </FormRow>
                </Form>
            </Card.Content>
        </Card>
    )
}

export default OrgForm;
