import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { apiRequest } from "../../functions";
import Card from "../commmon/Card";
import useScreenSize from "../../hooks/useScreenSize";
import { useOrgData } from "../../context/OrgContext";
import InputField from "../fields/InputField";
import Collapsible from "../commmon/Collapsible";
import SportForm from "./forms/SportForm";
import { ReactComponent as HomeIcon } from "../../svgs/home.svg";

import "../../styles/scss/admin-season-select.scss";

const SeasonSelect = function() {
    const { screenSize } = useScreenSize();
    const nav = useNavigate();
    const { org } = useOrgData();
    const [sportSeasons, setSportSeasons] = useState<Array<Sport>>([]);
    const [filteredSeasons, setFilteredSeasons] = useState<Array<Sport>>([]);
    const [selectedSport, setSelectedSport] = useState<number>(-1);
    const [showSportForm, setShowSportForm] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");

    useEffect(() => {
        if (org) {
            onRefreshSports();
        }
    }, [org?.id])

    useEffect(() => {
        console.log(screenSize);
    }, [screenSize])

    function onRefreshSports() {
        setShowSportForm(false);

        apiRequest<Array<Sport>>(`/sports/${org?.id}/all/?withSeasons=true`)
        .then((sports) => {
            setSportSeasons(sports.data || [])
            setFilteredSeasons(sports.data || []);
        })
        .catch((err) => console.error(err))
    }

    function onSeasonSelected(season: Season | undefined) {
        if (season) {
            nav(`${season.id}`);
        }
        else {
            nav(`new`);
        }
    }

    function onFilterSeasonName(e: React.ChangeEvent<HTMLInputElement>) {
        const value: string = e.currentTarget.value;
        setSearchValue(() => value);

        if (value) {
            setFilteredSeasons(sportSeasons.map((sport) => {
                return {
                    ...sport,
                    seasons: sport.seasons.filter(x => x.name.includes(value))
                }
            }))
        }
        else {
            setFilteredSeasons(sportSeasons);
        }
    }

    return (
        <div className="admin-season-selection">
            <h3 className="breadcrumb">
                <Link to="../">
                    <HomeIcon className="icon" /> 
                </Link> - Seasons
            </h3>
            <div>
                <Card onClick={() => onSeasonSelected(undefined)}>
                    <Card.Header>
                        <h3>Create New Season</h3>
                    </Card.Header>
                </Card>
            </div>
            <div className="filters">
                <div>
                    <div>Season Name:</div>
                    <InputField name="seasonSearch" label="" placeholder="Search Season By Name" value={searchValue} onChange={onFilterSeasonName} />
                </div>
            </div>
            <div className="sports">
                {
                    filteredSeasons.map((sport) => (
                        <div key={`sport-${sport.id}`} className="sport">
                            <Collapsible 
                                title={sport.name} 
                                footer={<div className="center"><sub>{ sport.seasons.length } seasons</sub></div>}
                                className="card button"
                                showNav={selectedSport === sport.id}
                                onClick={() => setSelectedSport(sport.id)}
                            >
                                <section>
                                    <h4 className="center">Active</h4>
                                    <ul>
                                        {
                                            sport.seasons.filter(x => x.current).map((season) => (
                                                <li key={`season-${season.id}`}>
                                                    <Card onClick={() => onSeasonSelected(season)}>
                                                        <Card.Header>{season.name}</Card.Header>
                                                        <Card.Content className="center">{season.seasonType.name} {season.year}</Card.Content>
                                                        <Card.Footer>{season.current ? "Active" : "Inactive"}</Card.Footer>
                                                    </Card>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </section>
                                <section>
                                    <h4 className="center">Inactive</h4>
                                    <ul>
                                        {
                                            sport.seasons.filter(x => !x.current).map((season) => (
                                                <li key={`season-${season.id}`}>
                                                    <Card onClick={() => onSeasonSelected(season)}>
                                                        <Card.Header>{season.name}</Card.Header>
                                                        <Card.Content className="center">{season.seasonType.name} {season.year}</Card.Content>
                                                        <Card.Footer>{season.current ? "Active" : "Inactive"}</Card.Footer>
                                                    </Card>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </section>
                            </Collapsible>
                        </div>
                    ))
                }

                <Card>
                    {
                        showSportForm ? (
                            <SportForm isEditing={true}  onFormSubmitted={onRefreshSports}/>
                        ) : (
                            <Card.Header>
                                <h3 onClick={() => setShowSportForm(true)}>
                                    Create New Sport
                                </h3>
                            </Card.Header>
                        )
                    }
                </Card>
            </div>
        </div>
    )

}

export default SeasonSelect;